import { useCallback, useState } from "react";
import { ISubscriptionCreateRequest } from "../../interfaces/ISubscriptionCreateRequest";
import { ISubscription } from "../../interfaces/ISubscriptions";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useCreateSubscription = (): [
  any | undefined,
  boolean,
  Error | undefined,
  (
    consumerId: string,
    subscriptionCreateRequest: ISubscriptionCreateRequest
  ) => Promise<any>
] => {
  const [data, setData] = useState<any>();

  const { post, response, loading, error } = useConsumerController<any>(false);

  const getConsumer = useCallback(
    async (
      consumerId: string,
      subscriptionCreateRequest: ISubscriptionCreateRequest
    ) => {
      const consumer = await post(
        `/${consumerId}/subscriptions`,
        subscriptionCreateRequest
      );

      setData(consumer);

      return consumer;
    },
    [post]
  );

  return [data, loading, error, getConsumer];
};

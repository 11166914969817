import { useEffect } from "react";
import { useAuthenticationContext } from "../../../context/authenticationContext/authenticationContext";
import { FullPageLoader } from "../../../components/fullPageLoader/fullPageLoader";

export const LogOut = () => {
  const { userManager } = useAuthenticationContext();

  useEffect(() => {
    const signoutRedirectAsync = async () => {
      if (userManager) {
        await userManager.signoutRedirect();
      }
    };

    signoutRedirectAsync();
  }, [userManager]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="Loggin' out" />
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { ProgressRadial, Modal, Table } from "@itwin/itwinui-react";
import { useGetSBQueueMonitor } from "../../hooks/useGetSBQueueMonitor/useGetSBQueueMonitor";
import { SBQueueStats } from "../../interfaces/ISBQueuesMonitor";
import React from "react";

import { DefaultCell } from "@itwin/itwinui-react";
import type { CellProps, CellRendererProps, Column } from "react-table";

export const SBQueueMonitorModal = (props: {
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
}) => {
  const { modalIsOpen, setModalIsOpen } = props;

  const [data, loading, error, getSBQueues] = useGetSBQueueMonitor();

  useEffect(() => {
    if (data === undefined) {
      getSBQueues();
    }
  }, [data, getSBQueues]);

  const sortedData = useMemo(() => {
    if (data?.serviceBusQueueMonitor?.queues !== undefined) {
      return data.serviceBusQueueMonitor.queues.sort(
        (a, b) => b.percentFull - a.percentFull
      );
    } else {
      return [];
    }
  }, [data]);

  const columns = React.useMemo(
    (): Column<SBQueueStats>[] => [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        width: "25%",
      },
      {
        id: "messages",
        Header: "Messages",
        accessor: "messages",
      },
      {
        id: "deadLetterMessages",
        Header: "Dead Letter Messages",
        accessor: "deadLetterMessages",
      },
      {
        id: "sizeInMB",
        Header: "Size In MB",
        accessor: "sizeInMB",
        Cell: (props: CellProps<SBQueueStats>) => {
          return <>{props.value} MB</>;
        },
      },
      {
        id: "percentFull",
        Header: "Percent Full",
        accessor: "percentFull",
        cellRenderer: (props: CellRendererProps<SBQueueStats>) => {
          return (
            <DefaultCell
              {...props}
              status={
                props.cellProps.row.original.percentFull > 50
                  ? "negative"
                  : props.cellProps.row.original.percentFull > 10
                  ? "warning"
                  : "positive"
              }
            >
              {props.cellProps.row.original.percentFull}%
            </DefaultCell>
          );
        },
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Queues`}
    >
      {loading || data?.serviceBusQueueMonitor?.queues === undefined ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        <div style={{ width: "900px" }}>
          <Table
            columns={columns}
            emptyTableContent="No data."
            data={sortedData}
            density="condensed"
            isSortable
            style={{ height: "85vh" }}
          />
        </div>
      )}
    </Modal>
  );
};

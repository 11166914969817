import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import {
  IEventDefinitionRequest,
  IEventDefinitionUpdateRequest,
} from "../../interfaces/IEventDefinitionRequest";
import { useAdminController } from "../useAdminController/useAdminController";

export const useEventDefinitionUpdate = (): [
  any | undefined,
  boolean,
  Error | undefined,
  (
    producerId: string,
    eventDefinitionId: string,
    eventDefinition: IEventDefinitionRequest,
    reason: string
  ) => Promise<void>
] => {
  const { user } = useAuthenticationContext();
  const { patch, response, loading, error } = useAdminController<any>(
    user!,
    false
  );

  const update = useCallback(
    async (
      producerId: string,
      eventDefinitionId: string,
      eventDefinition: IEventDefinitionRequest,
      reason: string
    ) => {
      var body: IEventDefinitionUpdateRequest = {
        eventDefinition,
        reason,
      };
      await patch(
        `/producers/${producerId}/eventdefinitions/${eventDefinitionId}`,
        body
      );
    },
    [patch]
  );

  return [response.data, loading, error, update];
};

import { useCallback, useState } from "react";
import { IConfiguration } from "../../interfaces/IConfiguration";
import { useClientAppController } from "../useClientAppController/useClientAppController";

export const useGetConfiguration = (): [
  IConfiguration | undefined,
  boolean,
  Error | undefined,
  () => Promise<IConfiguration>
] => {
  const [data, setData] = useState<IConfiguration>();

  const {
    get,
    response,
    loading,
    error,
  } = useClientAppController<IConfiguration>();

  const fetchConfiguration = useCallback(async () => {
    const config = (await get("GetConfiguration")) as IConfiguration;

    if (response.ok) {
      setData(config);
    }

    return config;
  }, [get, setData, response]);

  return [data, loading, error, fetchConfiguration];
};

import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { IConsumerCreateRequest } from "../../interfaces/IConsumerCreateRequest";
import { useAdminController } from "../useAdminController/useAdminController";

export const useConsumerAdminUpdate = (): [
  any | undefined,
  boolean,
  Error | undefined,
  (
    consumerId: string,
    consumerUpdateRequest: IConsumerCreateRequest
  ) => Promise<any>
] => {
  const { user } = useAuthenticationContext();
  const { patch, response, loading, error } = useAdminController<any>(
    user!,
    false
  );

  const update = useCallback(
    async (
      consumerId: string,
      consumerUpdateRequest: IConsumerCreateRequest
    ) => {
      let result = await patch(
        `/consumers/${consumerId}`,
        consumerUpdateRequest
      );
      return result;
    },
    [patch]
  );

  return [response.data, loading, error, update];
};

import {
  SvgAdd,
  SvgDelete,
  SvgEdit,
  SvgInfoCircular,
  SvgLink,
} from "@itwin/itwinui-icons-react";
import {
  Divider,
  Button,
  Text,
  IconButton,
  Modal,
  Surface,
} from "@itwin/itwinui-react";
import { useEffect, useMemo, useState } from "react";
import {
  IConsumerCosmosEntity,
  ISubscriptionCosmosEntity,
} from "../../interfaces/IPagedAdminConsumers";
import { IProducer } from "../../interfaces/IProducer";
import { ISubscription } from "../../interfaces/ISubscriptions";
import { generateServiceBusQueueURL } from "../../utils/generateServiceBusQueueURL";
import { ConsumerDeletionModal } from "./consumerDeletionModal";
import { ConsumerEditModal } from "./consumerEditModal";
import { SubscriptionCreationModal } from "./subscriptionCreationModal";
import { SubscriptionExpandableBlock } from "./subscriptionExpandableBlock";

export const ConsumerTile = (props: {
  consumer: IConsumerCosmosEntity;
  subscriptions: ISubscriptionCosmosEntity[];
  producers: IProducer[];
  isAdmin: boolean;
  refreshConsumers: () => void;
  refreshSubscriptions: () => void;
}) => {
  const {
    consumer,
    subscriptions,
    producers,
    isAdmin,
    refreshConsumers,
    refreshSubscriptions,
  } = props;
  const [jsonModalIsOpen, setJsonModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [subscriptionCreationModalIsOpen, setSubscriptionCreationModalIsOpen] =
    useState(false);
  const [allSubscriptions, setAllSubscriptions] =
    useState<ISubscriptionCosmosEntity[]>(subscriptions);

  useEffect(() => {
    setAllSubscriptions(subscriptions);
  }, [subscriptions, setAllSubscriptions]);

  const rawJsonWithoutSubscriptions = useMemo(() => {
    let withoutSubscriptions = structuredClone(consumer);
    //delete withoutSubscriptions.subscriptions;
    return JSON.stringify(withoutSubscriptions, null, 4);
  }, [consumer]);

  return (
    <Surface
      elevation={1}
      style={{
        height: "600px",
        width: "420px",
        margin: "14px",
      }}
    >
      <Surface.Header>
        <Text variant="subheading" as="h2">
          {consumer.id}
        </Text>
        <IconButton
          onClick={() => setJsonModalIsOpen(true)}
          style={{ border: "none", marginLeft: "auto" }}
        >
          <SvgInfoCircular />
        </IconButton>
        <Modal
          isOpen={jsonModalIsOpen}
          onClose={() => setJsonModalIsOpen(false)}
          closeOnEsc
          isDismissible
          title="Raw Json"
        >
          <div>
            <pre>{rawJsonWithoutSubscriptions}</pre>
          </div>
        </Modal>
      </Surface.Header>
      <Surface.Body isPadded={true}>
        <Text variant="body" as="p" style={{ marginBottom: "4px" }}>
          {consumer.description}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>CreatorId:</b> {consumer.creatorId}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>MaintainerIds:</b> {consumer.maintainerIds.join(", ")}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>GPRId:</b> {consumer.serviceGPRId}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Status:</b> {consumer.status}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>IsReady:</b> {String(consumer.isReady)}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Created:</b> {new Date(consumer.createdTimestamp).toDateString()}{" "}
          {consumer.modifiedTimestamp !== consumer.createdTimestamp &&
          consumer.modifiedTimestamp
            ? `(Modified: ${new Date(
                consumer.modifiedTimestamp
              ).toDateString()})`
            : ""}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>SB Queue:</b>{" "}
          {consumer.connectionDetails?.queueId ?? "NOT CONNECTED"}{" "}
          {isAdmin && (
            <a
              href={generateServiceBusQueueURL(
                consumer.connectionDetails?.queueId ?? ""
              )}
              rel="noreferrer"
              target="_blank"
            >
              <SvgLink />
            </a>
          )}
        </Text>
        {!isAdmin && (
          <>
            <Button
              startIcon={<SvgAdd />}
              style={{ marginBottom: "5px", marginRight: "5px" }}
              onClick={() => setSubscriptionCreationModalIsOpen(true)}
            >
              Add Subscription
            </Button>
            <SubscriptionCreationModal
              consumerId={consumer.id}
              modalIsOpen={subscriptionCreationModalIsOpen}
              setModalIsOpen={setSubscriptionCreationModalIsOpen}
              setNewSubscription={(sub: ISubscription) => {
                setAllSubscriptions([...allSubscriptions, sub.subscription]);
                setSubscriptionCreationModalIsOpen(false);
              }}
            />
            <Button
              startIcon={<SvgDelete />}
              style={{ marginBottom: "5px", marginRight: "5px" }}
              onClick={() => setDeleteModalIsOpen(true)}
            >
              Delete
            </Button>
            <ConsumerDeletionModal
              consumer={consumer}
              modalIsOpen={deleteModalIsOpen}
              setModalIsOpen={setDeleteModalIsOpen}
              refreshConsumers={refreshConsumers}
            />
          </>
        )}
        <Button
          startIcon={<SvgEdit />}
          onClick={() => setEditModalIsOpen(!editModalIsOpen)}
          style={{ marginBottom: "5px", marginRight: "5px" }}
        >
          Edit
        </Button>
        <ConsumerEditModal
          consumer={consumer}
          refreshConsumers={refreshConsumers}
          modalIsOpen={editModalIsOpen}
          setModalIsOpen={(open: boolean) => {
            setEditModalIsOpen(open);
          }}
          isAdmin={isAdmin}
        />
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <b>Subscriptions: </b>
        <div>
          {allSubscriptions.map((subscription) => (
            <SubscriptionExpandableBlock
              subscription={subscription}
              producers={producers}
              key={subscription.id}
              refreshSubscriptions={refreshSubscriptions}
              editable={!isAdmin}
              deletable={!isAdmin}
            />
          ))}
        </div>
      </Surface.Body>
    </Surface>
  );
};

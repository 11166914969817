import { useCallback, useState } from "react";
import { ISubscriptions } from "../../interfaces/ISubscriptions";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useDeleteConsumer = (): [
  boolean | undefined,
  boolean,
  Error | undefined,
  (consumerId: string) => Promise<void>
] => {
  const [data, setData] = useState<boolean | undefined>();

  const { del, response, loading, error } = useConsumerController(false);

  const deleteConsumer = useCallback(
    async (consumerId: string) => {
      await del(`/${consumerId}`);
      setData(error === undefined);
    },
    [del, error]
  );

  return [data, loading, error, deleteConsumer];
};

import { SvgDelete, SvgEdit } from "@itwin/itwinui-icons-react";
import { Button, ExpandableBlock, Modal, Text } from "@itwin/itwinui-react";
import { useMemo, useState } from "react";
import { ISubscriptionCosmosEntity } from "../../interfaces/IPagedAdminConsumers";
import { IProducer } from "../../interfaces/IProducer";
import { SubscriptionDeletionModal } from "./subscriptionDeletionModal";
import { SubscriptionEditModal } from "./subscriptionEditModal";

export const SubscriptionExpandableBlock = (props: {
  subscription: ISubscriptionCosmosEntity;
  producers: IProducer[];
  editable?: boolean;
  deletable?: boolean;
  refreshSubscriptions: () => void;
}) => {
  const { subscription, producers, editable, deletable, refreshSubscriptions } =
    props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const producerName = useMemo(() => {
    let producer = producers.filter((x) => x.id === subscription.producerId);
    return producer.length !== 0 ? producer[0].name : "-";
  }, [producers, subscription.producerId]);

  return (
    <ExpandableBlock
      title={producerName}
      size="small"
      style={{ marginBottom: "4px", marginTop: "4px" }}
    >
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Id:</b> {subscription.id}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Status:</b> {subscription.status}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>IsReady:</b> {String(subscription.isReady)}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Created:</b> {new Date(subscription.createdTimestamp).toDateString()}{" "}
        {subscription.modifiedTimestamp !== subscription.createdTimestamp &&
        subscription.modifiedTimestamp
          ? `(Modified: ${new Date(
              subscription.modifiedTimestamp
            ).toDateString()})`
          : ""}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Events:</b> {subscription.events.join(", ")}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Account Filter:</b> {subscription.accountIdFilter ?? "-"}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>iTwin Id Filter:</b> {subscription.itwinIdFilter ?? "-"}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>iTwin Data Center Id Filter:</b>{" "}
        {subscription.iTwinDataCenterIdFilter ?? "-"}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
        <b>Resource Data Center Id Filter:</b>{" "}
        {subscription.resourceDataCenterIdFilter ?? "-"}
      </Text>
      <Button
        onClick={() => setModalIsOpen(true)}
        style={{ marginBottom: "5px" }}
      >
        View JSON Subscription
      </Button>
      <br />
      {(editable === undefined || editable === true) && (
        <Button
          style={{ marginBottom: "5px", marginRight: "5px" }}
          startIcon={<SvgEdit />}
          onClick={() => setEditModalIsOpen(true)}
        >
          Edit
        </Button>
      )}
      {(deletable === undefined || deletable === true) && (
        <Button
          style={{ marginBottom: "5px" }}
          startIcon={<SvgDelete />}
          onClick={() => setDeleteModalIsOpen(true)}
          color="red"
        >
          Delete
        </Button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        closeOnEsc
        isDismissible
        title={`${producerName} Subscription - Raw Json`}
      >
        <div>
          <pre>{JSON.stringify(subscription, null, 4)}</pre>
        </div>
      </Modal>
      <SubscriptionDeletionModal
        subscription={subscription}
        producerName={producerName}
        modalIsOpen={deleteModalIsOpen}
        setModalIsOpen={setDeleteModalIsOpen}
        refreshSubscriptions={refreshSubscriptions}
      />
      <SubscriptionEditModal
        subscription={subscription}
        producer={producers.find((x) => x.id === subscription.producerId)!}
        modalIsOpen={editModalIsOpen}
        setModalIsOpen={setEditModalIsOpen}
        refreshSubscriptions={refreshSubscriptions}
      />
    </ExpandableBlock>
  );
};

import React, { ReactNode, useState } from "react";

export interface IAdminAuthenticationContext {
  isAdmin: boolean;
  setIsAdmin: (adminStatus: boolean) => void;
}

export const AdminAuthenticationContext =
  React.createContext<IAdminAuthenticationContext>({
    isAdmin: false,
    setIsAdmin: () => {},
  });

export const AdminAuthenticationContextProvider = (props: {
  children: ReactNode;
}) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const setAdmin = (adminStatus: boolean) => {
    setIsAdmin(adminStatus);
  };

  return (
    <AdminAuthenticationContext.Provider
      value={{ isAdmin, setIsAdmin: setAdmin }}
    >
      {props.children}
    </AdminAuthenticationContext.Provider>
  );
};

export function useAdminAuthenticationContext() {
  const context = React.useContext(AdminAuthenticationContext);
  if (context == null) {
    throw new Error(
      "useAdminAuthenticationContext must be used inside provider"
    );
  }
  return context;
}

import { Button, Flex, Text } from "@itwin/itwinui-react";
import eventsGif from "../../images/event-service-home-page.gif";
import { ReactComponent as ProducerExample } from "../../images/producer_example.svg";
import { ReactComponent as ConsumerExample } from "../../images/consumer_example.svg";
import { useNavigate } from "react-router";

export const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="column"
      gap="2xl"
      style={{
        margin: "0 11%",
        height: `300vh`,
      }}
    >
      <Flex.Item flex={1}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Flex.Item flex={1}>
            <Flex flexDirection="column" alignItems="flex-start" gap="xl">
              <Text
                variant="headline"
                as="h1"
                style={{ fontSize: "4em", lineHeight: "1.2" }}
              >
                <strong>What is the iTwin Event Service?</strong>
              </Text>
              <Text
                variant="subheading"
                style={{ fontSize: "3em", lineHeight: "1.2" }}
              >
                The iTwin Events Service is an easy way to produce and consume
                events to and from different iTwin Platform services.
              </Text>

              <Flex gap="xl">
                <Button styleType="default" onClick={() => navigate("/events")}>
                  Learn more
                </Button>
              </Flex>
            </Flex>
          </Flex.Item>

          <Flex.Item flex={1}>
            <div>
              <img
                src={eventsGif}
                alt="Producer"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Flex.Item>
        </Flex>
      </Flex.Item>

      <Flex.Item flex={1}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100%" }}
          gap="2xl"
        >
          <Flex.Item flex={1}>
            <ProducerExample style={{ maxWidth: "100%", height: "auto" }} />
          </Flex.Item>

          <Flex.Item flex={1}>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap="xl"
              style={{ marginLeft: "24px" }}
            >
              <Text
                variant="headline"
                as="h1"
                style={{ fontSize: "4em", lineHeight: "1.2" }}
              >
                <strong>What are Producers?</strong>
              </Text>
              <Text
                variant="subheading"
                style={{ fontSize: "3em", lineHeight: "1.2" }}
              >
                Producers are services that create events. Events are created
                through our API or through our inner sourced Connect Core
                Library provider.
              </Text>

              <Flex gap="xl">
                <Button
                  styleType="default"
                  onClick={() => navigate("/producer/documentation")}
                >
                  Learn more
                </Button>
              </Flex>
            </Flex>
          </Flex.Item>
        </Flex>
      </Flex.Item>

      <Flex.Item flex={1}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100%" }}
          gap="2xl"
        >
          <Flex.Item flex={1}>
            <Flex flexDirection="column" alignItems="flex-start" gap="xl">
              <Text
                variant="headline"
                as="h1"
                style={{ fontSize: "4em", lineHeight: "1.2" }}
              >
                <strong>What are Consumers?</strong>
              </Text>
              <Text
                variant="subheading"
                style={{ fontSize: "3em", lineHeight: "1.2" }}
              >
                Consumers are services or applications that will receive events
                created by our producers. This will allow your service or
                applications to stay consisitent with changes across our iTwin
                Platform
              </Text>

              <Flex gap="xl">
                <Button
                  styleType="high-visibility"
                  onClick={() =>
                    (window.location.href =
                      "https://bentley.sharepoint.com/:v:/s/ConnectCoreLibs/ERizlBAucU9AgolPjTYNxN4BfN2tm7vCnnyMlpHlvNT8hQ?e=uFL7WX")
                  }
                >
                  Demo
                </Button>
                <Button
                  styleType="default"
                  onClick={() => navigate("/consumer/documentation")}
                >
                  Learn more
                </Button>
              </Flex>
            </Flex>
          </Flex.Item>

          <Flex.Item flex={1}>
            <ConsumerExample style={{ maxWidth: "100%", height: "auto" }} />
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </Flex>
  );
};

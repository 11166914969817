import { useCallback, useState } from "react";
import { IConsumer } from "../../interfaces/IConsumers";
import { IConsumerCreateRequest } from "../../interfaces/IConsumerCreateRequest";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useCreateConsumer = (): [
  any,
  boolean,
  Error | undefined,
  (consumerRequestBody: IConsumerCreateRequest) => Promise<any>
] => {
  const [consumer, setData] = useState<IConsumer>();

  const { post, response, loading, error } = useConsumerController<any>(false);

  const createConsumer = useCallback(
    async (consumerRequestBody: IConsumerCreateRequest) => {
      const consumer = await post("", consumerRequestBody);

      setData(consumer);
      return consumer;
    },
    [post]
  );

  return [consumer, loading, error, createConsumer];
};

import { User, UserManager } from "oidc-client-ts";
import React, { ProviderProps, useState } from "react";
import { FullPageLoader } from "../../components/fullPageLoader/fullPageLoader";
import { createUserManager } from "../../userManager";
import { useAdminAuthenticationContext } from "../adminAuthenticationContext/adminAuthenticationContext";
import { useConfigurationContext } from "../configurationContext/configurationContext";

export interface AuthenticationContextProps {
  user: User | null;
  setUser: (user: User) => any;
  userManager: UserManager | null;
}

interface AuthenticationContextProviderProps
  extends Omit<ProviderProps<AuthenticationContextProps>, "value"> {}

export const AuthenticationContext =
  React.createContext<AuthenticationContextProps>({
    user: null,
    setUser: () => {},
    userManager: null,
  });

export const AuthenticationContextProvider = (
  props: AuthenticationContextProviderProps
) => {
  const [user, setUser] = useState<User | null>(null);
  const [userManager, setUserManager] = useState<UserManager>({} as any);
  const [isAuthenticationLoading, setIsAuthenticationLoading] = useState(true);

  const { configuration } = useConfigurationContext();

  React.useEffect(() => {
    const getUserAsync = async () => {
      setIsAuthenticationLoading(true);

      const manager = createUserManager(
        configuration.authority,
        configuration.clientId
      );

      const user = await manager.getUser();

      setUser(user);
      setUserManager(manager);

      setIsAuthenticationLoading(false);
    };

    getUserAsync();
  }, [configuration.authority, configuration.clientId]);

  return isAuthenticationLoading ? (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="Loggin' in" />
    </div>
  ) : (
    <AuthenticationContext.Provider
      value={{ user, setUser, userManager }}
      {...props}
    />
  );
};

export const useAuthenticationContext = () => {
  const context = React.useContext(AuthenticationContext);
  if (context == null) {
    throw new Error("authenticationContext must be used inside provider");
  }
  return context;
};

import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { useGovernanceController } from "../useGovernanceController/useGovernanceController";

export const useGovernance = (
  governanceType: GovernanceType
): [
  any | undefined,
  boolean,
  Error | undefined,
  (id: string, result: boolean, reason?: string) => Promise<void>
] => {
  const { user } = useAuthenticationContext();
  const { post, response, loading, error } = useGovernanceController<any>(
    user!,
    false
  );

  const update = useCallback(
    async (id: string, approve: boolean, reason?: string) => {
      await post(`/${governanceType + "s"}/${id}`, { approve, reason });
    },
    [post, governanceType]
  );

  return [response.data, loading, error, update];
};

export type GovernanceType = "Producer" | "EventDefinition";

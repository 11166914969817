import { FullPageLoader } from "../fullPageLoader/fullPageLoader";
import { useEffect } from "react";
import { FullPageError } from "../fullPageError/fullPageError";
import { useCheckForAuthorization } from "../../hooks/useCheckForAuthorization/useCheckForAuthorization";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { Svg403 } from "@itwin/itwinui-illustrations-react";
import { useCheckForAdminAuthorization } from "../../hooks/useCheckForAdminAuthorization/useCheckForAdminAuthorization";
import { useAdminAuthenticationContext } from "../../context/adminAuthenticationContext/adminAuthenticationContext";

const PermissionsBoundary: React.FunctionComponent<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const { user } = useAuthenticationContext();
  const { setIsAdmin } = useAdminAuthenticationContext();

  const [hasAuthorization, authLoading, , fetchCheckForAuthorization] =
    useCheckForAuthorization();

  const [
    hasAdminAuthorization,
    adminAuthLoading,
    ,
    fetchAdminCheckForAuthorization,
  ] = useCheckForAdminAuthorization();

  useEffect(() => {
    if (!adminAuthLoading && hasAdminAuthorization !== undefined) {
      setIsAdmin(hasAdminAuthorization);
    } else {
      setIsAdmin(false);
    }
  }, [adminAuthLoading, hasAdminAuthorization, setIsAdmin]);

  useEffect(() => {
    const checkForAuthorizationWrapper = async () => {
      await fetchCheckForAuthorization();
      await fetchAdminCheckForAuthorization();
    };

    if (user) {
      checkForAuthorizationWrapper();
    }
  }, [user, fetchCheckForAuthorization, fetchAdminCheckForAuthorization]);

  return authLoading ||
    hasAuthorization === undefined ||
    adminAuthLoading ||
    hasAdminAuthorization === undefined ? (
    <FullPageLoader loadingText="Loggin' in..." />
  ) : hasAuthorization ? (
    <>{children}</>
  ) : (
    <FullPageError
      svg={<Svg403 />}
      heading="You do not have access to this page."
    />
  );
};

export default PermissionsBoundary;

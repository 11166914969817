import { ExpandableBlock } from "@itwin/itwinui-react";
import { IConsumerAdminEntity } from "../../../interfaces/IPagedAdminConsumers";

export const SubscribedConsumersExpandableBlock = (props: {
  consumers: IConsumerAdminEntity[];
}) => {
  const { consumers } = props;
  const title = `Subscribed Consumers (${consumers.length})`;

  return (
    <ExpandableBlock
      title={title}
      size="small"
      style={{ marginBottom: "4px", marginTop: "4px" }}
    >
      <ul>
        {consumers.map((consumer) => (
          <li key={consumer.id}>
            {consumer.creatorId} ({consumer.serviceGPRId})
          </li>
        ))}
      </ul>
    </ExpandableBlock>
  );
};

import { useCallback, useState } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import {
  IConsumerAdminEntity,
  IPagedAdminConsumers,
} from "../../interfaces/IPagedAdminConsumers";
import { useAdminController } from "../useAdminController/useAdminController";

export const useGetAdminConsumersData = (): [
  IConsumerAdminEntity[] | undefined,
  boolean,
  Error | undefined,
  (useCache?: boolean) => Promise<IConsumerAdminEntity[]>
] => {
  const { user } = useAuthenticationContext();
  const [data, setData] = useState<IConsumerAdminEntity[]>();

  const { get, response, loading, error } =
    useAdminController<IPagedAdminConsumers>(user!, false);

  const getConsumers = useCallback(
    async (useCache: boolean = true) => {
      const allConsumers: IConsumerAdminEntity[] = [];
      let consumers = (await get(
        `Consumers?useCache=${useCache}`
      )) as IPagedAdminConsumers;
      allConsumers.push(...consumers.items);

      // Gets the rest of the paged results, should never be many
      while (consumers.nextPageLink !== "" && consumers.nextPageLink !== null) {
        const relativeUrl = `${
          consumers.nextPageLink.split("/Admin/")[1]
        }&useCache=${useCache}`;
        consumers = (await get(relativeUrl)) as IPagedAdminConsumers;
        allConsumers.push(...consumers.items);
      }

      if (response.ok) {
        setData(allConsumers);
      }

      return allConsumers;
    },
    [get, response]
  );

  return [data, loading, error, getConsumers];
};

import { User } from "oidc-client-ts";
import { useCallback } from "react";
import useFetch, { CachePolicies, Options } from "use-http";

export const useDotNetController = <T,>(
  controllerName: string,
  user?: User,
  cacheRequest: boolean = true
) => {
  const options = useCallback(
    (options: Options) => {
      return {
        ...options,
        headers: {
          Authorization: `Bearer ${user && user.access_token}`,
        },
        cachePolicy: cacheRequest
          ? CachePolicies.CACHE_FIRST
          : CachePolicies.NO_CACHE,
      };
    },
    [cacheRequest, user]
  );

  return useFetch<T>(`${window.location.origin}/${controllerName}`, options);
};

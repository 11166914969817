import { FullPageLoader } from "../../components/fullPageLoader/fullPageLoader";
import { Anchor, Code, Flex, Text } from "@itwin/itwinui-react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula, docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useThemeContext, ThemeType } from "../../context/themeContext/themeContext";
import { RefObject, useCallback, useEffect, useMemo } from "react";
import { MarkdownSidebarNavigation } from "../../components/markdownSidebarNavigation/markdownSidebarNavigation";
import { useDynamicRefs } from "../../utils/useDynamicRefs";
import { generateSlug } from "../../utils/generateSlug";
import rehypeRaw from "rehype-raw";

export const DocumentationPage = (props: {
  title: string,
  markDown: string,
  markDownLoading: boolean
}) => {
  const { title, markDown, markDownLoading } = props;

  const { theme } = useThemeContext();
  const [getRef, setRef] = useDynamicRefs();

  const headings = useMemo(() => {
    return markDown.split("\n").filter(s => s.startsWith("#"));;
  }, [markDown]);

  const scrollToComponent = useCallback(() => {
    if (window.location.hash !== '') {
      const ref = getRef(window.location.hash.substring(1)) as RefObject<HTMLHeadingElement>;

      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
        ref.current.focus();
      }
    }
  }, [getRef]);

  useEffect(() => {
    if (!markDownLoading) {
      scrollToComponent();
    }
  }, [markDownLoading, scrollToComponent])

  if (markDownLoading) {
    return <FullPageLoader loadingText="Loading documentation..." />
  }

  return (
    <Flex flexDirection="column" alignItems={"flex-start"} gap="0">
      <div style={{ width: "100%", borderBottom: "1px solid var(--iui-color-border-subtle)", paddingLeft: "38px" }}>
        <Text variant='headline'>{title}</Text>
      </div>
      <Flex alignItems={"flex-start"} style={{ width: "100%" }} >
        <Flex.Item flex="1" style={{ position: "sticky", top: "0" }}>
          <MarkdownSidebarNavigation headings={headings} scrollToComponent={scrollToComponent} />
        </Flex.Item>

        <Flex.Item flex="6" style={{ borderLeft: "1px solid var(--iui-color-border-subtle)" }}>
          <Flex flexDirection="column" alignItems={"flex-start"} justifyContent={"flex-start"} style={{ margin: "16px 0 0 16px", paddingRight: "36px" }}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              components={{
                h2: ({ node, ...props }) => {
                  const slug = generateSlug(props.children!.toString())
                  return <Text variant='title' as="h2" id={slug} ref={setRef(slug) as RefObject<HTMLHeadingElement>}>{props.children}</Text>
                },
                h3: ({ node, ...props }) => {
                  const slug = generateSlug(props.children!.toString())
                  return <Text variant='subheading' as="h3" id={slug} ref={setRef(slug) as RefObject<HTMLHeadingElement>}>{props.children}</Text>
                },
                li: ({ node, ...props }) => <li><Text variant='body'>{props.children}</Text></li>,
                p: ({ node, ...props }) => <>{props.children}</>,
                a: ({ node, ...props }) => <Anchor href={props.href as string} target="_blank">{props.children}</Anchor>,
                code: ({ className, children }) => {
                  const match = /language-(\w+)/.exec(className || '')

                  return match ? (
                    <SyntaxHighlighter
                      language={match[1]}
                      style={theme === ThemeType.Dark ? darcula : docco}
                    >
                      {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                  ) : (
                    <Code>
                      {children}
                    </Code>
                  )
                }
              }}>
              {markDown}
            </ReactMarkdown>
          </Flex>
        </Flex.Item>
      </Flex >
    </Flex >
  );
};

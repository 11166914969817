import { useCallback, useState } from "react";
import { IConsumers } from "../../interfaces/IConsumers";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useGetConsumers = (): [
  IConsumers | undefined,
  boolean,
  Error | undefined,
  () => Promise<IConsumers>
] => {
  const [data, setData] = useState<IConsumers>();

  const { get, response, loading, error } =
    useConsumerController<IConsumers>(false);

  const getConsumers = useCallback(async () => {
    const consumers = await get("");

    if (response.ok) {
      setData(consumers);
    }

    return consumers;
  }, [get, response]);

  return [data, loading, error, getConsumers];
};

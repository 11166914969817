import { useEffect, useState } from "react";
import {
  Button,
  LabeledInput,
  LabeledSelect,
  ProgressRadial,
  Text,
} from "@itwin/itwinui-react";
import { useCreateConsumer } from "../../hooks/useCreateConsumer/useCreateConsumer";
import { IConsumerCreateRequest } from "../../interfaces/IConsumerCreateRequest";
import { IConsumer } from "../../interfaces/IConsumers";

export const ConsumerCreationManager = (props: {
  setConsumer: (consumer: IConsumer) => void;
}) => {
  const { setConsumer } = props;
  const [gprId, setGprId] = useState("0");

  const [consumerDetails, setConsumerDetails] =
    useState<IConsumerCreateRequest>({
      maintainerIds: [],
      description: "",
      serviceGPRId: 0,
      status: "Active",
    });

  const [consumer, loading, error, createConsumer] = useCreateConsumer();
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Disabled", label: "Disabled" },
  ];

  useEffect(() => {
    if (
      consumer !== undefined &&
      consumer?.error === undefined &&
      !loading &&
      !error
    ) {
      setConsumer(consumer);
    }
  }, [consumer, error, loading, setConsumer]);

  useEffect(() => {
    setConsumerDetails({
      ...consumerDetails,
      serviceGPRId: !isNaN(Number.parseInt(gprId)) ? Number.parseInt(gprId) : 0,
    });
  }, [gprId]);

  return (
    <>
      <Text
        variant="headline"
        as="h1"
        style={{
          width: "100%",
          borderBottom: "1px solid var(--iui-color-border-subtle)",
          paddingLeft: "38px",
        }}
      >
        Create Consumer
      </Text>
      <div style={{ margin: "15px" }}>
        <LabeledInput
          label="Description"
          value={consumerDetails.description}
          onChange={(e) =>
            setConsumerDetails({
              ...consumerDetails,
              description: e.target.value,
            })
          }
          style={{ marginBottom: "12px", width: "25%" }}
        />
        <LabeledInput
          label="ServiceGPRId"
          value={gprId}
          onChange={(e) => setGprId(e.target.value.trim())}
          style={{ marginBottom: "12px", width: "25%" }}
        />
        <LabeledInput
          label="MaintainerIds"
          placeholder="Comma separated list of emails or client credential Ids"
          value={consumerDetails.maintainerIds.join(",")}
          onChange={(e) =>
            setConsumerDetails({
              ...consumerDetails,
              maintainerIds: e.target.value.split(","),
            })
          }
          style={{ marginBottom: "12px", width: "25%" }}
        />
        <LabeledSelect
          label="Status"
          options={statusOptions}
          value={consumerDetails.status}
          onChange={(value) =>
            setConsumerDetails({ ...consumerDetails, status: value })
          }
          style={{ marginBottom: "12px", width: "25%" }}
        />
        {error !== undefined || consumer?.error?.message ? (
          <Text style={{ color: "red" }}>
            {consumer?.error?.message ?? error?.message ?? "Unknown error"}
          </Text>
        ) : (
          ""
        )}
        {loading ? (
          <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
        ) : (
          ""
        )}
        <Button onClick={() => createConsumer(consumerDetails)}>Create</Button>
      </div>
    </>
  );
};

import { UserManager } from "oidc-client-ts";

export const createUserManager = (authority: string, client_id: string) => {
  return new UserManager({
    authority,
    client_id,
    response_type: "code",
    query_status_response_type: "code",
    redirect_uri: `${window.location.origin}/signin-oidc`,
    silent_redirect_uri: `${window.location.origin}/silent-signin-oidc`,
    post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
    scope:
      "openid email profile itwin-events-service",
    automaticSilentRenew: true,
    // accessTokenExpiringNotificationTimeInSeconds: 3540,
  });
};

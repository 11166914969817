import { SvgDelete, SvgEdit } from "@itwin/itwinui-icons-react";
import {
  Button,
  Modal,
  ExpandableBlock,
  ProgressRadial,
  Text,
} from "@itwin/itwinui-react";
import { useCallback, useMemo, useState } from "react";
import { useDeleteSubscription } from "../../hooks/useDeleteSubscription/useDeleteSubscription";
import { IProducerAdminEntity } from "../../interfaces/IAdminProducers";
import { ISubscriptionCosmosEntity } from "../../interfaces/IPagedAdminConsumers";
import { IProducer } from "../../interfaces/IProducer";

export const SubscriptionDeletionModal = (props: {
  subscription: ISubscriptionCosmosEntity;
  producerName: string;
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  refreshSubscriptions: () => void;
}) => {
  const {
    subscription,
    producerName,
    modalIsOpen,
    setModalIsOpen,
    refreshSubscriptions,
  } = props;
  const [succeeded, loading, error, deleteSubscription] =
    useDeleteSubscription();

  const deleteSub = async () => {
    await deleteSubscription(subscription.consumerId, subscription.id);
    if (!error) {
      setModalIsOpen(false);
      refreshSubscriptions();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Confirm Deletion`}
    >
      <div>
        Are you sure you want to delete this subscription to {producerName}?
      </div>
      <Button onClick={deleteSub}>Confirm</Button>
      <Button
        onClick={() => setModalIsOpen(false)}
        style={{ marginLeft: "5px" }}
      >
        Cancel
      </Button>
      {error !== undefined ? (
        <Text style={{ color: "red" }}>{"Unknown error"}</Text>
      ) : (
        ""
      )}
      {loading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        ""
      )}
    </Modal>
  );
};

import {
  Button,
  Checkbox,
  Modal,
  Flex,
  Label,
  LabeledInput,
  LabeledSelect,
  ProgressRadial,
  Text,
} from "@itwin/itwinui-react";
import { useEffect, useState } from "react";
import { usePatchSubscription } from "../../hooks/usePatchSubscription/usePatchSubscription";
import { ISubscriptionCosmosEntity } from "../../interfaces/IPagedAdminConsumers";
import { IProducer } from "../../interfaces/IProducer";
import { ISubscriptionCreateRequest } from "../../interfaces/ISubscriptionCreateRequest";

export const SubscriptionEditModal = (props: {
  subscription: ISubscriptionCosmosEntity;
  producer: IProducer;
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  refreshSubscriptions: () => void;
}) => {
  const {
    subscription,
    producer,
    modalIsOpen,
    setModalIsOpen,
    refreshSubscriptions,
  } = props;

  const [data, loading, error, patchConsumer] = usePatchSubscription();

  const [updatedSubscription, setUpdatedSubscription] = useState<
    Omit<ISubscriptionCreateRequest, "producerId">
  >({
    status: subscription.status,
    accountIdFilter: subscription.accountIdFilter,
    iTwinIdFilter: subscription.itwinIdFilter,
    resourceDataCenterIdFilter: subscription.resourceDataCenterIdFilter,
    iTwinDataCenterIdFilter: subscription.iTwinDataCenterIdFilter,
    events:
      subscription.events.length === 0
        ? producer.eventDefinitions.map((x) => x.eventType)
        : subscription.events,
  });

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Disabled", label: "Disabled" },
  ];

  const toggleEvent = (eventType: string, isChecked: boolean) => {
    let events = updatedSubscription.events;
    if (events.includes(eventType) && !isChecked) {
      events = events.filter((x) => x !== eventType);
      setUpdatedSubscription({ ...updatedSubscription, events });
    }
    if (!events.includes(eventType) && isChecked) {
      events.push(eventType);
      setUpdatedSubscription({ ...updatedSubscription, events });
    }
  };

  const updateSub = async () => {
    let result = await patchConsumer(
      subscription.consumerId,
      subscription.id,
      updatedSubscription
    );
    if (result?.error === undefined) {
      setModalIsOpen(false);
      refreshSubscriptions();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title="Edit Subscription"
    >
      <Label>Events</Label>
      <Flex flexDirection="row" flexWrap="wrap" style={{ width: "100%" }}>
        {producer?.eventDefinitions?.map((eventDef) => {
          return (
            <Flex.Item flex="1" key={eventDef.eventType}>
              <Checkbox
                label={eventDef.eventType}
                onChange={(event) =>
                  toggleEvent(eventDef.eventType, event.target.checked)
                }
                checked={updatedSubscription?.events.includes(
                  eventDef.eventType
                )}
              />
            </Flex.Item>
          );
        })}
      </Flex>
      <div>
        <LabeledSelect
          label="Status"
          options={statusOptions}
          value={updatedSubscription.status}
          onChange={(value) =>
            setUpdatedSubscription({
              ...updatedSubscription,
              status: value,
            })
          }
          style={{ marginBottom: "12px", width: "50%" }}
        />
      </div>
      <Flex flexDirection="row" style={{ width: "100%" }} flexWrap="wrap">
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="Account Id Filter"
            value={updatedSubscription.accountIdFilter}
            onChange={(event) =>
              setUpdatedSubscription({
                ...updatedSubscription,
                accountIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="iTwin Id Filter"
            value={updatedSubscription.iTwinIdFilter}
            onChange={(event) =>
              setUpdatedSubscription({
                ...updatedSubscription,
                iTwinIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="Resource Data Center Id"
            value={updatedSubscription.resourceDataCenterIdFilter}
            onChange={(event) =>
              setUpdatedSubscription({
                ...updatedSubscription,
                resourceDataCenterIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="iTwin Data Center Id"
            value={updatedSubscription.iTwinDataCenterIdFilter}
            onChange={(event) =>
              setUpdatedSubscription({
                ...updatedSubscription,
                iTwinDataCenterIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
      </Flex>

      <Flex flexDirection="row">
        <Button
          onClick={() => updateSub()}
          disabled={updatedSubscription.events.length == 0}
        >
          Save
        </Button>

        {updatedSubscription.events.length == 0 && (
          <Text style={{ color: "red" }}>
            Subscriptions must have events. To stop receiving events please
            disable the subscription, or remove it from the consumer.
          </Text>
        )}
      </Flex>

      {error !== undefined || data?.error?.message !== undefined ? (
        <Text style={{ color: "red" }}>
          {data?.error?.message ?? error?.message ?? "Unknown error"}
        </Text>
      ) : (
        ""
      )}
      {loading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        ""
      )}
    </Modal>
  );
};

import { Modal, ProgressRadial } from "@itwin/itwinui-react";
import { useEffect } from "react";
import { useGetDiscovery } from "../../hooks/useGetDiscovery/useGetDiscovery";
import { ISubscription } from "../../interfaces/ISubscriptions";
import { SubscriptionCreation } from "../subscriptionCreationManager/subscriptionCreation";

export const SubscriptionCreationModal = (props: {
  consumerId: string;
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  setNewSubscription: (newSubscription: ISubscription) => void;
}) => {
  const { consumerId, modalIsOpen, setModalIsOpen, setNewSubscription } = props;

  const [discovery, , , getDiscovery] = useGetDiscovery();

  // PJB_TODO: Eventually turn this into a context that is shared
  useEffect(() => {
    if (modalIsOpen && discovery === undefined) {
      getDiscovery();
    }
  }, [modalIsOpen, discovery, getDiscovery]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Create subscription`}
    >
      {discovery !== undefined && (
        <SubscriptionCreation
          consumerId={consumerId}
          setNewSubscription={setNewSubscription}
          discovery={discovery}
        />
      )}
      {discovery === undefined && (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      )}
    </Modal>
  );
};

import { ThemeProvider } from '@itwin/itwinui-react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

export interface IThemeContext {
  theme: ThemeType;
  setAppTheme: (theme: ThemeType) => void;
}

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
  Os = 'os',
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: ThemeType.Light,
  setAppTheme: () => { },
});

export const ThemeContextProvider = (props: { children: ReactNode }) => {
  const [theme, setTheme] = useState<ThemeType>(ThemeType.Light);
  const setAppTheme = useCallback((theme: ThemeType) => {
    localStorage.setItem('theme', theme);
    setTheme(theme);
  }, []);

  useEffect(() => {
    setTheme((localStorage.getItem('theme') as ThemeType) || ThemeType.Light);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setAppTheme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useThemeContext() {
  const context = React.useContext(ThemeContext);
  if (context == null) {
    throw new Error('useThemeContext must be used inside provider');
  }
  return context;
}

import { useGetConsumers } from "../../../hooks/useGetConsumers/useGetConsumers";
import { useEffect, useMemo, useState } from "react";
import { Button, Flex, ProgressRadial } from "@itwin/itwinui-react";
import { useGetSubscriptions } from "../../../hooks/useGetSubscriptions/useGetSubscriptions";
import {
  IConsumerCosmosEntity,
  ISubscriptionCosmosEntity,
} from "../../../interfaces/IPagedAdminConsumers";
import { ConsumerTile } from "../../../components/consumerTile/consumerTile";
import { useGetDiscovery } from "../../../hooks/useGetDiscovery/useGetDiscovery";
import { Navigate, useNavigate } from "react-router";

export const ManageConsumerPage = () => {
  const [consumers, consumersLoading, consumersError, getConsumers] =
    useGetConsumers();
  const [discovery, loading, error, getDiscovery] = useGetDiscovery();
  const [, , , getSubscriptions] = useGetSubscriptions();
  const [allSubscriptions, setAllSubscriptions] = useState<{
    [id: string]: ISubscriptionCosmosEntity[];
  }>({});

  const navigate = useNavigate();

  useEffect(() => {
    getConsumers();
    getDiscovery();
  }, [getConsumers, getDiscovery]);

  const getAllSubscriptions = async () => {
    if (consumers !== undefined && consumers.consumers !== undefined) {
      let tempAllSubscriptions: any = {};
      consumers.consumers.forEach(async (consumer: IConsumerCosmosEntity) => {
        let subs = await getSubscriptions(consumer.id);
        tempAllSubscriptions[consumer.id] = subs.subscriptions ?? [];
      });
      setAllSubscriptions(tempAllSubscriptions);
    }
  };

  const refreshSubscriptionsForConsumer = async (consumerId: string) => {
    let latestSubs = await getSubscriptions(consumerId);
    setAllSubscriptions({
      ...allSubscriptions,
      [consumerId]: latestSubs.subscriptions,
    });
  };

  useEffect(() => {
    getAllSubscriptions();
  }, [consumers, getSubscriptions, setAllSubscriptions]);

  return (
    <div style={{ margin: "0 5%" }}>
      <Flex alignItems="flex-start" style={{ width: "100%" }} flexWrap="wrap">
        {consumers !== undefined &&
          consumers.consumers !== undefined &&
          consumers.consumers.map((consumer) => {
            return (
              <Flex.Item key={consumer.id} flex="1 1 20%">
                <ConsumerTile
                  consumer={consumer}
                  subscriptions={allSubscriptions[consumer.id] ?? []}
                  producers={discovery?.producers ?? []}
                  refreshConsumers={getConsumers}
                  refreshSubscriptions={() =>
                    refreshSubscriptionsForConsumer(consumer.id)
                  }
                  key={consumer.id}
                  isAdmin={false}
                />
              </Flex.Item>
            );
          })}
        {consumers !== undefined &&
          consumers.consumers !== undefined &&
          consumers.consumers.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "calc(100vh - 110px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>No consumers found</div>
              <br />
              <Button
                onClick={() => {
                  navigate("/consumer/create");
                }}
              >
                Create Consumer
              </Button>
            </div>
          )}
        {consumers === undefined && (
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 110px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
          </div>
        )}
      </Flex>
    </div>
  );
};

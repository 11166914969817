import { useEffect, useState } from "react";
import {
  ProgressRadial,
  Modal,
  LabeledSelect,
  Checkbox,
  LabeledInput,
  Slider,
  Label,
  Button,
} from "@itwin/itwinui-react";
import { useSBDrainer } from "../../hooks/useSBDrainer/useSBDrainer";

export const SBDrainerModal = (props: {
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
}) => {
  const { modalIsOpen, setModalIsOpen } = props;
  const [type, setType] = useState("queue");
  const [topic, setTopic] = useState("");
  const [subscription, setSubscription] = useState("");
  const [queue, setQueue] = useState("");
  const [deadLetter, setDeadLetter] = useState(false);
  const [waitTime, setWaitTime] = useState(5);

  const [data, loading, error, drain] = useSBDrainer();

  useEffect(() => {
    setTopic("");
    setSubscription("");
    setQueue("");
  }, [type]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`SB Message Drainer`}
    >
      <div style={{ width: "900px" }}>
        <LabeledSelect
          label="Type"
          options={[
            { value: "queue", label: "Queue" },
            { value: "subscription", label: "Topic Subscription" },
          ]}
          value={type}
          onChange={setType}
          style={{ width: "25%", marginBottom: "10px" }}
        />
        {type === "queue" && (
          <div>
            <LabeledInput
              label="Queue"
              value={queue}
              onChange={(event) => {
                setQueue(event.target.value);
              }}
              style={{ width: "50%", marginBottom: "10px" }}
            />
          </div>
        )}
        {type === "subscription" && (
          <div>
            <LabeledInput
              label="Topic"
              value={topic}
              onChange={(event) => {
                setTopic(event.target.value);
              }}
              style={{ width: "50%", marginBottom: "10px" }}
            />
            <LabeledInput
              label="Subscription"
              value={subscription}
              onChange={(event) => {
                setSubscription(event.target.value);
              }}
              style={{ width: "50%", marginBottom: "10px" }}
            />
          </div>
        )}
        <Checkbox
          label="Dead Letter Messages"
          checked={deadLetter}
          onChange={(event) => setDeadLetter(!deadLetter)}
          style={{ marginBottom: "10px" }}
        />
        <Label>Wait time in seconds</Label>
        <Slider
          min={0}
          max={30}
          values={[waitTime]}
          onChange={(values) => setWaitTime(values[0])}
          style={{ width: "25%", marginBottom: "10px" }}
        />
        {data && <div>{JSON.stringify(data, null, 4)}</div>}
        <Button
          onClick={() => {
            drain(topic, subscription, queue, deadLetter, waitTime);
          }}
        >
          Drain
        </Button>
        {loading && (
          <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
        )}
      </div>
    </Modal>
  );
};

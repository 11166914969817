import { useState } from "react";
import { Stepper, Surface } from "@itwin/itwinui-react";
import { ConsumerCreationManager } from "../../../components/consumerCreationManager/consumerCreationManager";
import { IConsumer } from "../../../interfaces/IConsumers";
import { ConsumerDetailsManager } from "../../../components/consumerDetailsManager/consumerDetailsManager";
import { SubscriptionCreationManager } from "../../../components/subscriptionCreationManager/subscriptionCreationManager";

export const CreateConsumerPage = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [consumerEntity, setConsumerEntity] = useState<IConsumer>();

  const steps = [
    {
      name: "Create Consumer",
    },
    {
      name: "Create Subscriptions",
    },
    {
      name: "Consumer Connection Details",
    },
  ];

  const setConsumer = (consumer: IConsumer) => {
    setConsumerEntity(consumer);
    setCurrentStepIndex(1);
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <Stepper steps={steps} currentStep={currentStepIndex} />
      <Surface
        elevation={1}
        style={{
          height: "70vh",
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          marginTop: "25px",
        }}
      >
        {currentStepIndex === 0 && (
          <ConsumerCreationManager setConsumer={setConsumer} />
        )}
        {currentStepIndex === 1 &&
          consumerEntity?.consumer?.id !== undefined && (
            <SubscriptionCreationManager
              consumerId={consumerEntity.consumer.id}
              goToNext={() => setCurrentStepIndex(2)}
            />
          )}
        {currentStepIndex === 2 && (
          <ConsumerDetailsManager
            consumerId={consumerEntity?.consumer.id ?? ""}
          />
        )}
      </Surface>
    </div>
  );
};

import { useCallback } from "react";
import { IConsumerCreateRequest } from "../../interfaces/IConsumerCreateRequest";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const usePatchConsumer = (): [
  any | undefined,
  boolean,
  Error | undefined,
  (
    consumerId: string,
    consumerUpdateRequest: IConsumerCreateRequest
  ) => Promise<any>
] => {
  const { patch, response, loading, error } = useConsumerController<any>(false);

  const update = useCallback(
    async (
      consumerId: string,
      consumerUpdateRequest: IConsumerCreateRequest
    ) => {
      let result = await patch(`/${consumerId}`, consumerUpdateRequest);
      return result;
    },
    [patch]
  );

  return [response.data, loading, error, update];
};

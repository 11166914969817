import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { ISBQueuesMonitor } from "../../interfaces/ISBQueuesMonitor";
import { useAdminController } from "../useAdminController/useAdminController";

export const useSBDrainer = (): [
  string | undefined,
  boolean,
  Error | undefined,
  (
    topic: string,
    subscription: string,
    queue: string,
    deadLetter: boolean,
    waitSeconds: number
  ) => Promise<string>
] => {
  const { user } = useAuthenticationContext();
  const { get, response, loading, error } = useAdminController<string>(
    user!,
    false
  );

  const getSBQueues = useCallback(
    async (
      topic: string,
      subscription: string,
      queue: string,
      deadLetter: boolean,
      waitSeconds: number
    ) => {
      var endpoint = `messageDrainer?deadLetter=${deadLetter}&waitSeconds=${waitSeconds}`;
      if (topic !== "") {
        endpoint += `&topic=${topic}&subscription=${subscription}`;
      } else {
        endpoint += `&queue=${queue}`;
      }

      let result = await get(endpoint);
      return result;
    },
    [get]
  );

  return [response.data, loading, error, getSBQueues];
};

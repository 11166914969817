import { useEffect } from "react";
import { useAuthenticationContext } from "../../../context/authenticationContext/authenticationContext";
import { FullPageLoader } from "../../../components/fullPageLoader/fullPageLoader";

export const LogIn = () => {
  const { user, userManager } = useAuthenticationContext();

  useEffect(() => {
    const signinRedirectAsync = async () => {
      if ((user == null || user.expired) && userManager) {        
        const originalPath = window.location.pathname.replace(/^\/\w+/, "")
        
        await userManager.signinRedirect({
          state: { from: `${window.location.origin}${originalPath}` },
        });
      }
    };

    signinRedirectAsync();
  }, [user, userManager]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="Loggin' in" />
    </div>
  );
};

import { useEffect, useState } from "react";
import producerDocumentationMarkdown from "./producerDocumentionMarkdown.md";
import { DocumentationPage } from "../documentationPage";

export const ProducerDocumentationPage = () => {
  const [markDown, setMarkDown] = useState('');
  const [markDownLoading, setMarkDownLoading] = useState(true);

  useEffect(() => {
    const readMarkdownWrapper = async () => {
      const res = await fetch(producerDocumentationMarkdown);
      const mdText = await res.text();

      setMarkDown(mdText);
      setMarkDownLoading(false);
    }

    readMarkdownWrapper();
  }, [])

  return (
    <DocumentationPage title="Producers" markDown={markDown} markDownLoading={markDownLoading} />
  );
};

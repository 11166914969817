import { useCallback } from "react";
import useFetch, { CachePolicies, Options } from "use-http";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";

export const useITwinEventsServiceV1API = <T,>(
  baseUrl?: string,
  cacheRequest: boolean = true
) => {
  const { user } = useAuthenticationContext();

  const options = useCallback(
    (options: Options) => {
      return {
        ...options,
        headers: {
          Authorization: `Bearer ${user && user.access_token}`,
        },
        cachePolicy: cacheRequest
          ? CachePolicies.CACHE_FIRST
          : CachePolicies.NO_CACHE,
      };
    },
    [cacheRequest, user]
  );

  return useFetch<T>(
    `${window.location.origin}/api/v1/${baseUrl ? baseUrl : ""}`,
    options
  );
};

import { useCallback, useState } from "react";
import { ISubscriptions } from "../../interfaces/ISubscriptions";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useDeleteSubscription = (): [
  boolean | undefined,
  boolean,
  Error | undefined,
  (consumerId: string, subscriptionId: string) => Promise<void>
] => {
  const [data, setData] = useState<boolean | undefined>();

  const { del, response, loading, error } = useConsumerController(false);

  const deleteSubscription = useCallback(
    async (consumerId: string, subscriptionId: string) => {
      await del(`/${consumerId}/subscriptions/${subscriptionId}`);
      setData(error === undefined);
    },
    [del, error]
  );

  return [data, loading, error, deleteSubscription];
};

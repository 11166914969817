import React, { useMemo } from "react";
import {
  Avatar,
  DropdownMenu,
  getUserColor,
  Header,
  HeaderLogo,
  IconButton,
  MenuDivider,
  MenuExtraContent,
  MenuItem,
  Text,
  HeaderButton,
} from "@itwin/itwinui-react";
import { SvgImodel, SvgMoon, SvgSun } from "@itwin/itwinui-icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "oidc-client-ts";
import {
  ThemeType,
  useThemeContext,
} from "../../context/themeContext/themeContext";
import { useAdminAuthenticationContext } from "../../context/adminAuthenticationContext/adminAuthenticationContext";

const EventsServiceHeader: React.FunctionComponent<{
  user: User;
}> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, setAppTheme } = useThemeContext();
  const { isAdmin } = useAdminAuthenticationContext();

  const fullName = useMemo(
    () => user.profile.given_name + " " + user.profile.family_name,
    [user]
  );

  const headerButton = useMemo(
    () => [
      <HeaderButton
        name={"Home"}
        isActive={location.pathname === "/"}
        onClick={() => navigate("/")}
      />,
      <HeaderButton
        name={"Events"}
        isActive={location.pathname === "/events"}
        onClick={() => navigate("/events")}
      />,
      <HeaderButton
        name={"Producers"}
        isActive={location.pathname.startsWith("/producer")}
        menuItems={(close) => [
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/producer/documentation");
            }}
          >
            Documentation
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/producer/create");
            }}
          >
            Create
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/producer/manage");
            }}
          >
            Manage
          </MenuItem>,
        ]}
        onClick={() => {
          navigate("/producer/documentation");
        }}
      />,
      <HeaderButton
        name={"Consumers"}
        isActive={location.pathname.startsWith("/consumer")}
        menuItems={(close) => [
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/consumer/documentation");
            }}
          >
            Documentation
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/consumer/create");
            }}
          >
            Create
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              close();
              navigate("/consumer/manage");
            }}
          >
            Manage
          </MenuItem>,
        ]}
        onClick={() => {
          navigate("/consumer/documentation");
        }}
      />,
      <DropdownMenu
        menuItems={(close) => [
          <MenuExtraContent key={0}>
            <>
              <Text variant="leading">{fullName}</Text>
              <Text isMuted>{user.profile.email}</Text>
            </>
          </MenuExtraContent>,
          <MenuDivider key={1} />,
          <MenuItem
            key={2}
            icon={theme === ThemeType.Light ? <SvgSun /> : <SvgMoon />}
            onClick={() => {
              setAppTheme(
                theme === ThemeType.Light ? ThemeType.Dark : ThemeType.Light
              );
            }}
          >
            Toggle theme
          </MenuItem>,
          <MenuItem
            key={3}
            style={{ display: isAdmin ? "" : "none" }}
            onClick={() => {
              navigate("/adminportal");
            }}
          >
            Admin Portal
          </MenuItem>,
          <MenuItem
            key={4}
            onClick={() => {
              close();
              navigate(`/logout`);
            }}
          >
            Sign out
          </MenuItem>,
        ]}
      >
        <IconButton styleType="borderless">
          <Avatar
            abbreviation={fullName
              .split(" ")
              .map((n) => n[0].toUpperCase())
              .join("")}
            backgroundColor={getUserColor(fullName)}
            title={fullName}
          />
        </IconButton>
      </DropdownMenu>,
    ],
    [
      fullName,
      location.pathname,
      navigate,
      setAppTheme,
      theme,
      user.profile.email,
      isAdmin,
    ]
  );

  return (
    <Header
      appLogo={
        <HeaderLogo logo={<SvgImodel />}>
          <strong>iTwin Events Service</strong>
        </HeaderLogo>
      }
      actions={headerButton}
      isSlim={false}
    />
  );
};

export default EventsServiceHeader;

import {
  Button,
  Modal,
  Input,
  ProgressRadial,
  Text,
} from "@itwin/itwinui-react";
import { useCallback, useEffect, useState } from "react";
import {
  GovernanceType,
  useGovernance,
} from "../../../hooks/useGovernance/useGovernance";
import { IAdminProducers } from "../../../interfaces/IAdminProducers";

export const GovernanceButton = (props: {
  governanceType: GovernanceType;
  id: string;
  updateProducers: () => Promise<IAdminProducers>;
}) => {
  const { governanceType, id, updateProducers } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reason, setReason] = useState("");

  const [data, loading, error, update] = useGovernance(governanceType);

  const sendResponse = useCallback(
    (status: boolean) => {
      update(id, status, reason);
    },
    [id, reason, update]
  );

  useEffect(() => {
    if (
      loading === false &&
      error === undefined &&
      data !== undefined &&
      modalIsOpen
    ) {
      setModalIsOpen(false);
      updateProducers();
    }
  }, [modalIsOpen, data, error, loading, governanceType, updateProducers]);

  return (
    <>
      <Button size="small" onClick={() => setModalIsOpen(!modalIsOpen)}>
        Approve/Reject
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        closeOnEsc
        isDismissible
        title={`Approve or Reject ${governanceType}`}
      >
        <Text>Please provide a reason if rejecting</Text>
        <Input
          placeholder="Rejection Reason..."
          onChange={(e) => setReason(e.target.value)}
          style={{ marginBottom: "12px" }}
        />
        {error !== undefined ? (
          <Text style={{ color: "red" }}>
            {data?.error?.message ?? "Unknown error"}
          </Text>
        ) : (
          ""
        )}
        <Button
          style={{ marginRight: "12px" }}
          onClick={() => sendResponse(true)}
        >
          Approve
        </Button>
        <Button onClick={() => sendResponse(false)}>Reject</Button>
        {loading ? (
          <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

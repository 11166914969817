import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { ISBQueuesMonitor } from "../../interfaces/ISBQueuesMonitor";
import { useAdminController } from "../useAdminController/useAdminController";

export const useGetSBQueueMonitor = (): [
  ISBQueuesMonitor | undefined,
  boolean,
  Error | undefined,
  () => Promise<ISBQueuesMonitor>
] => {
  const { user } = useAuthenticationContext();
  const { get, response, loading, error } =
    useAdminController<ISBQueuesMonitor>(user!, false);

  const getSBQueues = useCallback(async () => {
    let result = await get(`serviceBusQueuesMonitor`);
    return result;
  }, [get]);

  return [response.data, loading, error, getSBQueues];
};

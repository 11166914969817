import { useCallback, useState } from "react";
import { IConsumer } from "../../interfaces/IConsumers";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useGetConsumer = (): [
  IConsumer | undefined,
  boolean,
  Error | undefined,
  (consumerId: string) => Promise<IConsumer>
] => {
  const [data, setData] = useState<IConsumer>();

  const { get, response, loading, error } =
    useConsumerController<IConsumer>(false);

  const getConsumer = useCallback(
    async (consumerId: string) => {
      const consumer = await get(`/${consumerId}`);

      if (response.ok) {
        setData(consumer);
      }

      return consumer;
    },
    [get, response]
  );

  return [data, loading, error, getConsumer];
};

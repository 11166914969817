import { Surface, Code, Divider, Button, Text } from "@itwin/itwinui-react"
import { useState } from "react";

export const EventTile = (
  props: {
    title: string, description: string, resourceIdDescription: string, eventSchema: string
  }) => {
  const { title, description, resourceIdDescription, eventSchema } = props;

  const [viewAllToggled, setViewAllToggled] = useState(false);

  // WSM_TODO: figure out transistion
  return (
    <Surface elevation={1} style={{ maxHeight: viewAllToggled ? 'none' : "300px", width: "550px", margin: "24px" }}>
      <Surface.Header>
        <Text variant='subheading' as='h2'>
          {title}
        </Text>
      </Surface.Header>
      <Surface.Body isPadded={true}>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}><b>Event Description: </b>{description}</Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}><b>ResourceId Description: </b>{resourceIdDescription}</Text>

        <Code style={{ whiteSpace: "pre-wrap", width: "100%", display: "inline-block" }}>
          {eventSchema}
        </Code>
      </Surface.Body>
      <Divider />
      <Button styleType='borderless' onClick={() => { setViewAllToggled(!viewAllToggled) }}>{!viewAllToggled ? "View All" : "Collapse"}</Button>
    </Surface>
  );
}

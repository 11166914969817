import { useEffect } from "react";
import { useAuthenticationContext } from "../../../context/authenticationContext/authenticationContext";
import { FullPageLoader } from "../../../components/fullPageLoader/fullPageLoader";

export const CompleteSignIn = () => {
  const { userManager, setUser } = useAuthenticationContext();

  useEffect(() => {
    const signinRedirectCallbackAsync = async () => {
      if (userManager) {
        const user = await userManager.signinRedirectCallback();
        setUser(user);

        window.location.href = (user.state as any).from;
      }
    };

    signinRedirectCallbackAsync();
  }, [setUser, userManager]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="Loggin' in" />
    </div>
  );
};

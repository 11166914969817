import { useCallback, useState } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { useAdminController } from "../useAdminController/useAdminController";

export const useCheckForAdminAuthorization = (): [
  boolean | undefined,
  boolean,
  Error | undefined,
  () => Promise<boolean>
] => {
  const { user } = useAuthenticationContext();
  const [hasAdminAuthorization, setHasAdminAuthorization] = useState<
    boolean | undefined
  >(undefined);

  const { get, response, loading, error } = useAdminController<any>(
    user!,
    false
  );

  const fetchAdminCheckForAuthorization = useCallback(async () => {
    await get("CheckForAuthorization");
    setHasAdminAuthorization(response.ok);

    return response.ok;
  }, [get, response]);

  return [
    hasAdminAuthorization,
    loading,
    error,
    fetchAdminCheckForAuthorization,
  ];
};

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ProgressRadial,
  Modal,
  Table,
  NonIdealState,
  Button,
  InformationPanel,
  InformationPanelHeader,
  InformationPanelBody,
  Label,
  Text,
} from "@itwin/itwinui-react";
import { useGetSBQueueMonitor } from "../../hooks/useGetSBQueueMonitor/useGetSBQueueMonitor";
import { SBQueueStats } from "../../interfaces/ISBQueuesMonitor";
import React from "react";

import { DefaultCell } from "@itwin/itwinui-react";
import type { CellProps, CellRendererProps, Column } from "react-table";
import { useAuditTrail } from "../../hooks/useAuditTrail/useAuditTrail";
import { SvgError } from "@itwin/itwinui-illustrations-react";
import { EventDefinitionAuditTrail } from "../../interfaces/IEventDefinitionAuditTrail";

export const EventDefAuditTrailModal = (props: {
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  id: string;
}) => {
  const { modalIsOpen, setModalIsOpen, id } = props;
  const [informationPanelIsOpen, setInformationPanelIsOpen] = useState(false);
  const [informationPanelId, setInformationPanelId] = useState<string>();

  const [auditTrails, loading, error, getAuditTrails] = useAuditTrail();

  const selectedEventDef = useMemo(() => {
    return auditTrails.find((x) => x.id === informationPanelId);
  }, [informationPanelId]);

  useEffect(() => {
    getAuditTrails(id);
  }, []);

  const columns = React.useMemo(
    (): Column<EventDefinitionAuditTrail>[] => [
      {
        id: "date",
        Header: "Date",
        accessor: "createdTimestamp",
        width: "10%",
        Cell: (props: CellProps<EventDefinitionAuditTrail>) => {
          return <>{new Date(props.value).toDateString()}</>;
        },
      },
      {
        id: "manual",
        Header: "Manual",
        accessor: "manualEdit",
        width: "12%",
        Cell: (props: CellProps<EventDefinitionAuditTrail>) => {
          return <>{props.value ? "True" : "False"}</>;
        },
      },
      {
        id: "reviewer",
        Header: "ReviewerId",
        accessor: "reviewer",
        width: "20%",
      },
      {
        id: "submitter",
        Header: "Submitter",
        accessor: "submitter",
        width: "20%",
      },
      {
        id: "justification",
        Header: "Justification",
        accessor: "justification",
        width: "25%",
      },
      {
        id: "details",
        Header: "Details",
        accessor: "id",
        cellRenderer: (props: CellRendererProps<EventDefinitionAuditTrail>) => {
          return (
            <Button
              onClick={() => {
                setInformationPanelId(props.cellProps.row.original.id);
                setInformationPanelIsOpen(true);
              }}
            >
              Latest
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Audit Trail`}
      style={{ width: "100%", height: "80%" }}
    >
      {loading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        <div style={{ width: "100%" }}>
          {auditTrails.length === 0 ? (
            <NonIdealState
              svg={<SvgError />}
              heading="Error"
              description={
                <>
                  The audit trail for this event definition does not exist. It
                  was likely created before the implementation of audit trails.
                </>
              }
            />
          ) : (
            <>
              <Table
                columns={columns}
                emptyTableContent="No data."
                data={auditTrails}
                density="condensed"
                isSortable
                style={{ height: "100%", width: "100%" }}
              />
              <InformationPanel isOpen={informationPanelIsOpen}>
                <InformationPanelHeader
                  onClose={() => setInformationPanelIsOpen(false)}
                >
                  Latest
                </InformationPanelHeader>
                <InformationPanelBody>
                  <Label>Name:</Label>
                  <Text>{selectedEventDef?.eventType}</Text>
                  <Label>Description:</Label>
                  <Text>{selectedEventDef?.description}</Text>
                  <Label>Schema:</Label>
                  <Text>
                    {JSON.stringify(
                      selectedEventDef?.eventDefinitionSchema,
                      null,
                      2
                    )}
                  </Text>
                </InformationPanelBody>
              </InformationPanel>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

import { useCallback, useState } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { useClientAppController } from "../useClientAppController/useClientAppController";

export const useCheckForAuthorization = (): [
  boolean | undefined,
  boolean,
  Error | undefined,
  () => Promise<boolean>
] => {
  const { user } = useAuthenticationContext();
  const [hasAuthorization, setHasAuthorization] = useState<boolean | undefined>(
    undefined
  );

  const { get, response, loading, error } = useClientAppController<any>(
    user!,
    false
  );

  const fetchCheckForAuthorization = useCallback(async () => {
    await get("CheckForAuthorization");

    if (response.ok) {
      setHasAuthorization(true);
    } else {
      setHasAuthorization(false);
    }

    return response.ok;
  }, [get, response]);

  return [hasAuthorization, loading, error, fetchCheckForAuthorization];
};

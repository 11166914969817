import { useEffect } from "react";
import { useAuthenticationContext } from "../../../context/authenticationContext/authenticationContext";
import { FullPageLoader } from "../../../components/fullPageLoader/fullPageLoader";

export const CompleteSignOut = () => {
  const { userManager } = useAuthenticationContext();

  useEffect(() => {
    const signoutRedirectCallbackAsync = async () => {
      if (userManager) {
        await userManager.signoutRedirectCallback();

        await userManager.signinRedirect({
          state: { from: `${window.location.origin}` },
        });
      }
    };

    signoutRedirectCallbackAsync();
  }, [userManager]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="Loggin' out" />
    </div>
  );
};

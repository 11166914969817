import { FullPageError } from "../../../components/fullPageError/fullPageError";
import { SvgError } from "@itwin/itwinui-illustrations-react";

export const ManageProducerPage = () => {
  return (
    <FullPageError
      svg={<SvgError />}
      heading="Under construction"
      description="This page will be here soon."
    />
  );
};

import { useCallback, useState } from "react";
import { Res } from "use-http";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { IDiscovery } from "../../interfaces/IDiscovery";
import { useClientAppController } from "../useClientAppController/useClientAppController";

export const useGetDiscovery = (): [IDiscovery | undefined, boolean, Error | undefined, () => Promise<IDiscovery>] => {
  const { user } = useAuthenticationContext();
  const [data, setData] = useState<IDiscovery>();

  const { get, response, loading, error } = useClientAppController<IDiscovery>(user!, false);

  const getDiscovery = useCallback(
    async () => {
      const discovery = (await get("GetDiscovery")) as IDiscovery;

      if (response.ok) {
        setData(discovery);
      }

      return discovery;
    },
    [get, response]
  );

  return [data, loading, error, getDiscovery];
};

import {
  Button,
  Modal,
  Divider,
  Input,
  LabeledInput,
  LabeledTextarea,
  ProgressRadial,
  Text,
  Textarea,
} from "@itwin/itwinui-react";
import { useCallback, useEffect, useState } from "react";
import { useEventDefinitionUpdate } from "../../../../hooks/useEventDefinitionUpdate/useEventDefinitionUpdate";
import {
  IAdminProducers,
  IEventDefinitionCosmosEntity,
} from "../../../../interfaces/IAdminProducers";
import { IEventDefinitionRequest } from "../../../../interfaces/IEventDefinitionRequest";

export const EventDefinitionEditModal = (props: {
  eventDefinition: IEventDefinitionCosmosEntity;
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  updateProducers: () => Promise<IAdminProducers>;
}) => {
  const { eventDefinition, modalIsOpen, setModalIsOpen, updateProducers } =
    props;
  const [editDetails, setEditDetails] = useState<IEventDefinitionRequest>({
    eventSchema: JSON.stringify(eventDefinition.eventSchema, null, 4),
    eventType: eventDefinition.eventType,
    description: eventDefinition.description,
    resourceIdDescription: eventDefinition.resourceIdDescription,
  });
  const [editReason, setEditReason] = useState("");
  const [customError, setCustomError] = useState<string | undefined>(undefined);

  const [data, loading, error, update] = useEventDefinitionUpdate();

  const sendUpdatedEventDef = useCallback(() => {
    let newDetails = editDetails;

    let jsonIsValid = true;
    let details = { ...newDetails };
    try {
      details.eventSchema =
        details.eventSchema === "" ? null : JSON.parse(details.eventSchema);
      setCustomError(undefined);
    } catch (e) {
      jsonIsValid = false;
      setCustomError(`Unable to parse JSON: ${e}`);
    }

    if (jsonIsValid)
      update(
        eventDefinition.producerId,
        eventDefinition.id,
        details,
        editReason
      );
  }, [
    editDetails,
    update,
    eventDefinition.producerId,
    eventDefinition.id,
    editReason,
  ]);

  useEffect(() => {
    if (
      loading === false &&
      error === undefined &&
      data !== undefined &&
      modalIsOpen
    ) {
      setModalIsOpen(false);
      updateProducers();
    }
  }, [modalIsOpen, data, error, loading, updateProducers, setModalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        closeOnEsc
        isDismissible
        title={`Edit Event Definition: ${eventDefinition.eventType}`}
      >
        <b>NOTE: This will permanently change the event definition.</b>
        <Text>EventType and description are required, schema is optional.</Text>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <LabeledInput
          label="EventType"
          value={editDetails.eventType}
          onChange={(e) =>
            setEditDetails({ ...editDetails, eventType: e.target.value })
          }
          style={{ marginBottom: "12px" }}
        />
        <LabeledInput
          label="Description"
          value={editDetails.description}
          onChange={(e) =>
            setEditDetails({ ...editDetails, description: e.target.value })
          }
          style={{ marginBottom: "12px" }}
        />
        <LabeledInput
          label="ResourceId Description"
          value={editDetails.resourceIdDescription}
          onChange={(e) =>
            setEditDetails({
              ...editDetails,
              resourceIdDescription: e.target.value,
            })
          }
          style={{ marginBottom: "12px" }}
        />
        <LabeledTextarea
          label="Schema"
          value={editDetails.eventSchema}
          onChange={(e) =>
            setEditDetails({
              ...editDetails,
              eventSchema: e.target.value,
            })
          }
          style={{ marginBottom: "12px" }}
        />
        <LabeledInput
          label="Edit reason"
          value={editReason}
          onChange={(e) => setEditReason(e.target.value)}
          style={{ marginBottom: "12px" }}
        />
        {error !== undefined || customError !== undefined ? (
          <Text style={{ color: "red" }}>
            {customError ?? data?.error?.message ?? "Unknown error"}
          </Text>
        ) : (
          ""
        )}
        <Button
          style={{ marginRight: "12px" }}
          onClick={() => sendUpdatedEventDef()}
        >
          Save
        </Button>
        {loading ? (
          <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Text } from "@itwin/itwinui-react";
import { useGetDiscovery } from "../../hooks/useGetDiscovery/useGetDiscovery";
import { SubscriptionCreation } from "./subscriptionCreation";
import { ISubscription } from "../../interfaces/ISubscriptions";
import { SubscriptionExpandableBlock } from "../consumerTile/subscriptionExpandableBlock";
import { useGetSubscriptions } from "../../hooks/useGetSubscriptions/useGetSubscriptions";
import { ISubscriptionCosmosEntity } from "../../interfaces/IPagedAdminConsumers";

export const SubscriptionCreationManager = (props: {
  consumerId: string;
  goToNext: () => void;
}) => {
  const { consumerId, goToNext: finish } = props;
  const [subscriptions, setSubscriptions] = useState<
    ISubscriptionCosmosEntity[]
  >([]);

  const [, , , getSubscriptions] = useGetSubscriptions();

  const refreshSubscriptions = async () => {
    let subs = await getSubscriptions(consumerId);
    setSubscriptions(subs.subscriptions);
  };

  const [discovery, , , getDiscovery] = useGetDiscovery();

  useEffect(() => {
    getDiscovery();
  }, [getDiscovery]);

  return (
    <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
      <Text
        variant="headline"
        as="h1"
        style={{
          width: "100%",
          borderBottom: "1px solid var(--iui-color-border-subtle)",
          paddingLeft: "38px",
        }}
      >
        Create Subscriptions
      </Text>
      <div style={{ margin: "15px", width: "calc(100% - 30px)" }}>
        <Text>Create subscriptions for your new consumer.</Text>
        <div style={{ width: "49%", float: "left" }}>
          {discovery !== undefined && (
            <SubscriptionCreation
              consumerId={consumerId}
              discovery={discovery}
              setNewSubscription={(sub: ISubscription) => {
                setSubscriptions([...subscriptions, sub.subscription]);
              }}
            />
          )}
        </div>
        <div style={{ width: "49%", float: "right", height: "500px" }}>
          {subscriptions.length !== 0 &&
            discovery !== undefined &&
            subscriptions.map((sub) => (
              <SubscriptionExpandableBlock
                subscription={sub}
                producers={discovery!.producers}
                refreshSubscriptions={refreshSubscriptions}
                editable={false}
              />
            ))}
        </div>
      </div>
      {/* TODO: Try to fix this mess of a CSS to get it lined up */}
      <div
        style={{
          float: "left",
          marginLeft: "15px",
          marginTop: "15px",
        }}
      >
        <Button onClick={finish}>Next</Button>
      </div>
    </div>
  );
};

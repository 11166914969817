import { useCallback, useState } from "react";
import { ISubscriptions } from "../../interfaces/ISubscriptions";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const useGetSubscriptions = (): [
  ISubscriptions | undefined,
  boolean,
  Error | undefined,
  (consumerId: string) => Promise<ISubscriptions>
] => {
  const [data, setData] = useState<ISubscriptions>();

  const { get, response, loading, error } =
    useConsumerController<ISubscriptions>(false);

  const getSubscriptions = useCallback(
    async (consumerId: string) => {
      const subscriptions = await get(`/${consumerId}/subscriptions`);

      if (response.ok) {
        setData(subscriptions);
      }

      return subscriptions;
    },
    [get, response]
  );

  return [data, loading, error, getSubscriptions];
};

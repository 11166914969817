import { useEffect, useState } from "react";
import consumerDocumentationMarkdown from "./consumerDocumentionMarkdown.md";
import { DocumentationPage } from "../documentationPage";

export const ConsumerDocumentationPage = () => {
  const [markDown, setMarkDown] = useState('');
  const [markDownLoading, setMarkDownLoading] = useState(true);

  useEffect(() => {
    const readMarkdownWrapper = async () => {
      const res = await fetch(consumerDocumentationMarkdown);
      const mdText = await res.text();

      setMarkDown(mdText);
      setMarkDownLoading(false);
    }

    readMarkdownWrapper();
  }, [])

  return (
    <DocumentationPage title="Consumers" markDown={markDown} markDownLoading={markDownLoading} />
  );
};

import { useEffect, useState } from "react";
import { Button, ProgressRadial, Text } from "@itwin/itwinui-react";
import { useGetConsumer } from "../../hooks/useGetConsumer/useGetConsumer";
import { useNavigate } from "react-router";

export const ConsumerDetailsManager = (props: { consumerId: string }) => {
  const { consumerId } = props;
  const [consumer, loading, error, getConsumer] = useGetConsumer();
  const navigate = useNavigate();

  useEffect(() => {
    getConsumer(consumerId);
  }, [consumerId, getConsumer]);

  useEffect(() => {
    if (consumer !== undefined && consumer.consumer.isReady === false) {
      setTimeout(() => getConsumer(consumerId), 1000);
    }
  }, [consumer, consumerId, getConsumer]);

  return (
    <>
      <Text
        variant="headline"
        as="h1"
        style={{
          width: "100%",
          borderBottom: "1px solid var(--iui-color-border-subtle)",
          paddingLeft: "38px",
        }}
      >
        Consumer Connection Details
      </Text>
      <div style={{ margin: "15px" }}>
        <Text>
          Use the following connection information to connect to your consumer
          service bus queue.
        </Text>
        <br />
        <b>Queue Id:</b> {consumer?.consumer?.connectionDetails?.queueId ?? ""}
        <br />
        <b>Namespace:</b>{" "}
        {consumer?.consumer?.connectionDetails?.namespace ?? ""}
        <br />
        <b>Access Key:</b>{" "}
        {consumer?.consumer?.connectionDetails?.accessKey ?? ""}
        <br />
        {loading ||
        consumer?.consumer?.connectionDetails?.queueId === undefined ? (
          <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
        ) : (
          ""
        )}
        <Button
          onClick={() =>
            navigate(
              "/consumer/documentation#5-setting-up-a-service-bus-connection",
              { replace: true }
            )
          }
          style={{ marginTop: "15px" }}
        >
          View Service Bus Setup Instructions
        </Button>
        <Button
          onClick={() => navigate("/consumer/manage", { replace: true })}
          style={{ marginLeft: "15px", marginTop: "15px" }}
        >
          Manage Consumers
        </Button>
      </div>
    </>
  );
};

import { useGetDiscovery } from "../../hooks/useGetDiscovery/useGetDiscovery";
import { RefObject, useCallback, useEffect, useMemo } from "react";
import { FullPageLoader } from "../../components/fullPageLoader/fullPageLoader";
import { Code, Flex, Text } from "@itwin/itwinui-react";
import { EventTile } from "../../components/eventTile/eventTile";
import { MarkdownSidebarNavigation } from "../../components/markdownSidebarNavigation/markdownSidebarNavigation";
import { useDynamicRefs } from "../../utils/useDynamicRefs";
import { generateSlug } from "../../utils/generateSlug";

export const EventsPage = () => {
  const [discovery, discoveryLoading, , getDiscovery] = useGetDiscovery();
  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    const fetchDiscoveryAsync = async () => {
      await getDiscovery();
    };

    fetchDiscoveryAsync();
  }, [getDiscovery]);

  const headings = useMemo(() => {
    const eventHeadings = [] as string[];
    if (discovery && !discoveryLoading) {
      discovery.producers.map((p) => {
        eventHeadings.push("# " + p.name);
        p.eventDefinitions.map((e) => eventHeadings.push("## " + e.eventType));

        return 0;
      });
    }

    return eventHeadings;
  }, [discovery, discoveryLoading]);

  const scrollToComponent = useCallback(() => {
    if (discovery && !discoveryLoading) {
      if (window.location.hash !== "") {
        const ref = getRef(
          window.location.hash.substring(1)
        ) as RefObject<HTMLHeadingElement>;

        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
          ref.current.focus();
        }
      }
    }
  }, [discovery, discoveryLoading, getRef]);

  useEffect(() => {
    if (discovery && !discoveryLoading) {
      scrollToComponent();
    }
  }, [discovery, discoveryLoading, scrollToComponent]);

  if (!discovery || discoveryLoading) {
    return <FullPageLoader loadingText="Loading events..." />;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      style={{ width: "100%", height: "calc(100% - 50px)", overflow: "hidden" }}
      gap="0"
    >
      <Text
        variant="headline"
        as="h1"
        style={{
          width: "100%",
          borderBottom: "1px solid var(--iui-color-border-subtle)",
          paddingLeft: "38px",
        }}
      >
        Events
      </Text>

      <Flex alignItems="flex-start" gap="0" style={{ width: "100%", height: "calc(100% - 48px)" }}>
        <Flex.Item flex="1" style={{ height: "100%", overflowY: "scroll" }}>
          <MarkdownSidebarNavigation
            headings={headings}
            scrollToComponent={scrollToComponent}
          />
        </Flex.Item>

        <Flex.Item
          flex="6"
          style={{ borderLeft: "1px solid var(--iui-color-border-subtle)", paddingRight: "8px", height: "100%", overflowY: "scroll" }}
        >
          <>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              style={{ margin: "16px 0 0 16px" }}
            >
              <Text
                variant="title"
                as="h2"
                id={generateSlug("EventOverview")}
                ref={
                  setRef(
                    generateSlug("EventOverview")
                  ) as RefObject<HTMLDivElement>
                }
              >
                Event Delivery Overview
              </Text>
              <Text style={{ marginLeft: "24px" }}>
                All events are delivered in a JSON formatted body of a service
                bus message. There are a certain number of required properties
                that come with every type of event in the
                'applicationProperties' of the service bus (not the 'body').
                These include: accountId, iTwinId, iTwinDataCenterId,
                resourceDataCenterId, correlationId, source, type, resourceId,
                and dataSchema. The event specific schema data (listed for each
                producers' events further below) will be included in the service
                bus message's 'body' property as a JSON string. The resourceId
                will always correspond the Id of whatever the primary subject of
                the given event is. For example, in a role.created.v1 event, the
                resourceId is the Id of the new role. What an event's resourceId
                represents is often listed in the description of the event.
              </Text>
              <Text style={{ marginLeft: "24px" }}>
                The following is an example of what a checkpointv1.created.v1
                event might look like in service bus. The 'body' property will
                conform to the checkpointv1.created.v1 schema, whereas the
                'applicationProperties' schema will be constant no matter what
                type of event is received. Please note that this is a JSON
                representation of the service bus message to demonstrate what
                properties are available and what it will look like. Reading an
                actual service bus message may look different for you depending
                on how you access the service bus message and what SDK you use.
              </Text>
              <Code
                style={{
                  whiteSpace: "pre-wrap",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                {
                  '{\n  "applicationProperties": {\n    "accountId":"a4ec441e-9781-4d9e-87fc-cec9b98f02d2",\n    "iTwinId":"a6d5c1d4-d049-4c54-8148-b9a27d5772d8",\n    "iTwinDataCenterId":"99999999-9999-9999-9999-999999999999",\n    "resourceDataCenterId":"99999999-9999-9999-9999-999999999999",\n    "correlationId":"7bf70123-4243-4177-b10c-5132114bbd86",\n    "source":"/2485",\n    "type":"checkpointv1.created.v1",\n    "resourceId":"9bfd5761-2955-4d26-a6fc-88fe30e52224",\n    "dataSchema":"https://dev-itwineventsservice.bentley.com/api/v1/discovery/eventdefinitions/df72569b-706d-4c95-b6b7-a27ac7c1fa03"\n  },\n  "messageProperties": {\n    // Service Bus default properties go here\n  },\n  "body": {\n    "userId":"a437ceb4-11c5-43f2-b89a-4848583d98bd",\n    "changesetIndex":2289,\n    "changesetId":"124e9f25-339e-4065-a0d7-e6e30fc85eb1",\n    "versionId":"93db18ab-7bff-4e72-85ec-8df1f3bfa823"\n  }\n}'
                }
              </Code>
            </Flex>
          </>

          {discovery!.producers.map((p) => (
            <>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                style={{ margin: "16px 0 0 16px" }}
              >
                <Text
                  variant="title"
                  as="h2"
                  id={generateSlug(p.name)}
                  ref={
                    setRef(generateSlug(p.name)) as RefObject<HTMLDivElement>
                  }
                >
                  {p.name}
                </Text>
                <Text
                  variant="subheading"
                  as="h3"
                  style={{ marginLeft: "24px" }}
                >
                  {p.description}
                </Text>
                <Text variant="title" as="h2">
                  Events
                </Text>
                <Flex alignItems="flex-start" flexWrap="wrap" style={{}}>
                  {p.eventDefinitions.map((e) => {
                    const slug = generateSlug(e.eventType);

                    return (
                      <div
                        id={slug}
                        ref={setRef(slug) as RefObject<HTMLDivElement>}
                      >
                        <EventTile
                          key={slug}
                          title={e.eventType}
                          description={e.description}
                          resourceIdDescription={e.resourceIdDescription}
                          eventSchema={JSON.stringify(e.eventSchema, null, 4)}
                        />
                      </div>
                    );
                  })}
                </Flex>
              </Flex>
            </>
          ))}
        </Flex.Item>
      </Flex>
    </Flex>
  );
};

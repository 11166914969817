import React from "react";
import "@itwin/itwinui-layouts-css/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthenticationContextProvider } from "./context/authenticationContext/authenticationContext";
import { CompleteSignIn } from "./pages/authenticationRoutes/completeSignIn/completeSignIn";
import { CompleteSignOut } from "./pages/authenticationRoutes/completeSignOut/completeSignOut";
import { LogIn } from "./pages/authenticationRoutes/login/login";
import { LogOut } from "./pages/authenticationRoutes/logout/logout";
import Layout from "./components/layout/layout";
import { LandingPage } from "./pages/landingPage/landingPage";
import { ConfigurationContextProvider } from "./context/configurationContext/configurationContext";
import AuthorizationBoundary from "./components/authorizationBoundary/authorizationBoundary";
import { ThemeContextProvider } from "./context/themeContext/themeContext";
import { EventsPage } from "./pages/eventsPage/eventsPage";
import { PageLayout } from "@itwin/itwinui-layouts-react";
import { ProducerDocumentationPage } from "./pages/documentation/producerDocumentationPage/producerDocumentationPage";
import { ConsumerDocumentationPage } from "./pages/documentation/consumerDocumentationPage/consumerDocumentationPage";
import { CreateProducerPage } from "./pages/create/createProducerPage/createProducerPage";
import { ManageProducerPage } from "./pages/manage/manageProducersPage/manageProducersPage";
import { CreateConsumerPage } from "./pages/create/createConsumerPage/createConsumerPage";
import { ManageConsumerPage } from "./pages/manage/manageConsumersPage/manageConsumersPage";
import { AdminAuthenticationContextProvider } from "./context/adminAuthenticationContext/adminAuthenticationContext";
import { AdminPortalPage } from "./pages/admin/adminPortalPage/adminPortalPage";

function App() {
  return (
    <>
      <ThemeContextProvider>
        <PageLayout>
          <ConfigurationContextProvider>
            <AdminAuthenticationContextProvider>
              <AuthenticationContextProvider>
                <Router>
                  <Routes>
                    {[
                      {
                        path: "/",
                        element: <LandingPage />,
                      },
                      {
                        path: "events",
                        element: <EventsPage />,
                      },
                      {
                        path: "producer/documentation",
                        element: <ProducerDocumentationPage />,
                      },
                      {
                        path: "producer/create",
                        element: <CreateProducerPage />,
                      },
                      {
                        path: "producer/manage",
                        element: <ManageProducerPage />,
                      },
                      {
                        path: "consumer/documentation",
                        element: <ConsumerDocumentationPage />,
                      },
                      {
                        path: "consumer/create",
                        element: <CreateConsumerPage />,
                      },
                      {
                        path: "consumer/manage",
                        element: <ManageConsumerPage />,
                      },
                      {
                        path: "adminportal",
                        element: <AdminPortalPage />,
                      },
                    ].map(({ path, element }, index) => {
                      return (
                        <Route
                          path={path}
                          element={
                            <Layout>
                              <AuthorizationBoundary>
                                {element}
                              </AuthorizationBoundary>
                            </Layout>
                          }
                          key={index}
                        />
                      );
                    })}
                    <Route path="/login/*" element={<LogIn />} />
                    <Route path="/logout" element={<LogOut />} />
                    <Route path="/signin-oidc" element={<CompleteSignIn />} />
                    <Route path="/signout-oidc" element={<CompleteSignOut />} />
                  </Routes>
                </Router>
              </AuthenticationContextProvider>
            </AdminAuthenticationContextProvider>
          </ConfigurationContextProvider>
        </PageLayout>
      </ThemeContextProvider>
    </>
  );
}

export default App;

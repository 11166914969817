import { useCallback } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import {
  EventDefinitionAuditTrail,
  EventDefinitionAuditTrailResponse,
} from "../../interfaces/IEventDefinitionAuditTrail";
import { useAdminController } from "../useAdminController/useAdminController";

export const useAuditTrail = (): [
  EventDefinitionAuditTrail[],
  boolean,
  Error | undefined,
  (id: string) => Promise<EventDefinitionAuditTrail[]>
] => {
  const { user } = useAuthenticationContext();
  const { get, response, loading, error } =
    useAdminController<EventDefinitionAuditTrailResponse>(user!, false);

  const getAuditTrails = useCallback(
    async (id: string) => {
      let result = await get(`auditTrail?id=${id}`);
      return result.auditTrails;
    },
    [get]
  );

  return [response.data?.auditTrails ?? [], loading, error, getAuditTrails];
};

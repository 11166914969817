import { Flex, ProgressRadial, Text } from "@itwin/itwinui-react";
import "../../App.css";

interface FullPageLoaderProps {
  loadingText: string;
}

export const FullPageLoader = (props: FullPageLoaderProps) => {
  const { loadingText } = props;

  return (
    <Flex flexDirection="column" alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
      <ProgressRadial indeterminate />
      <Text variant='leading'>{loadingText}</Text>
    </Flex >
  );
};

import { useCallback, useState } from "react";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { IAdminProducers } from "../../interfaces/IAdminProducers";
import { useAdminController } from "../useAdminController/useAdminController";

export const useGetAdminProducersData = (): [
  IAdminProducers | undefined,
  boolean,
  Error | undefined,
  () => Promise<IAdminProducers>
] => {
  const { user } = useAuthenticationContext();
  const [data, setData] = useState<IAdminProducers>();

  const { get, response, loading, error } = useAdminController<IAdminProducers>(
    user!,
    false
  );

  const getProducers = useCallback(async () => {
    const producers = (await get("Producers")) as IAdminProducers;

    if (response.ok) {
      setData(producers);
    }

    return producers;
  }, [get, response]);

  return [data, loading, error, getProducers];
};

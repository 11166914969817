import React, { ProviderProps } from "react";
import { FullPageLoader } from "../../components/fullPageLoader/fullPageLoader";
import { useGetConfiguration } from "../../hooks/useGetConfiguration/useConfiguration";
import { IConfiguration } from "../../interfaces/IConfiguration";

export interface ConfigurationContextProps {
  configuration: IConfiguration;
}

interface ConfigurationContextProviderProps
  extends Omit<ProviderProps<ConfigurationContextProps>, "value"> {}

export const ConfigurationContext = React.createContext<ConfigurationContextProps>(
  {
    configuration: {} as IConfiguration,
  }
);

export const ConfigurationContextProvider = (
  props: ConfigurationContextProviderProps
) => {
  const [
    configuration,
    isConfigLoading,
    ,
    fetchConfiguration,
  ] = useGetConfiguration();

  React.useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  return isConfigLoading || !configuration ? (
    <div style={{ height: "100vh", width: "100vw" }}>
      <FullPageLoader loadingText="" />
    </div>
  ) : (
    <ConfigurationContext.Provider
      value={{ configuration: configuration! }}
      {...props}
    />
  );
};

export const useConfigurationContext = () => {
  const context = React.useContext(ConfigurationContext);
  if (context == null) {
    throw new Error("configurationContext must be used inside provider");
  }
  return context;
};

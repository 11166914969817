import {
  Button,
  Modal,
  Divider,
  LabeledInput,
  ProgressRadial,
  Text,
  LabeledSelect,
} from "@itwin/itwinui-react";
import { useState } from "react";
import { useConsumerAdminUpdate } from "../../hooks/useConsumerAdminUpdate/useConsumerAdminUpdate";
import { usePatchConsumer } from "../../hooks/usePatchConsumer/usePatchConsumer";
import { IConsumerCreateRequest } from "../../interfaces/IConsumerCreateRequest";
import { IConsumerCosmosEntity } from "../../interfaces/IPagedAdminConsumers";

export const ConsumerEditModal = (props: {
  consumer: IConsumerCosmosEntity;
  modalIsOpen: boolean;
  isAdmin: boolean;
  setModalIsOpen: (open: boolean) => void;
  refreshConsumers: () => void;
}) => {
  const { consumer, modalIsOpen, isAdmin, setModalIsOpen, refreshConsumers } =
    props;
  const [editDetails, setEditDetails] = useState<IConsumerCreateRequest>({
    maintainerIds: consumer.maintainerIds,
    description: consumer.description,
    serviceGPRId: consumer.serviceGPRId,
    status: consumer.status.toString(),
  });

  const [regularData, regularLoading, regularError, regularUpdate] =
    usePatchConsumer();
  const [adminData, adminLoading, adminError, adminUpdate] =
    useConsumerAdminUpdate();

  const sendUpdateConsumer = async () => {
    let result: any;
    if (isAdmin) {
      result = await adminUpdate(consumer.id, editDetails);
    } else {
      result = await regularUpdate(consumer.id, editDetails);
    }
    if (result?.error === undefined) {
      setModalIsOpen(false);
      refreshConsumers();
    }
  };

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Disabled", label: "Disabled" },
  ];

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Edit Consumer: ${consumer.id}`}
    >
      <b>NOTE: This will permanently change the consumer.</b>
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <LabeledInput
        label="MaintainerIds"
        value={editDetails.maintainerIds.join(",")}
        onChange={(e) =>
          setEditDetails({
            ...editDetails,
            maintainerIds: e.target.value.split(","),
          })
        }
        style={{ marginBottom: "12px" }}
      />
      <LabeledInput
        label="Description"
        value={editDetails.description}
        onChange={(e) =>
          setEditDetails({ ...editDetails, description: e.target.value })
        }
        style={{ marginBottom: "12px" }}
      />
      <LabeledInput
        label="ServiceGPRId"
        value={editDetails.serviceGPRId}
        inputMode="numeric"
        onChange={(e) =>
          setEditDetails({
            ...editDetails,
            serviceGPRId: Number.parseInt(e.target.value),
          })
        }
        style={{ marginBottom: "12px" }}
      />
      {/* Should have status be a select but there seems to be a bug with Select inside modal, or I'm dumb */}
      <LabeledSelect
        label="Status"
        options={statusOptions}
        value={editDetails.status}
        onChange={(value) =>
          setEditDetails({
            ...editDetails,
            status: value,
          })
        }
        style={{ marginBottom: "12px" }}
      />
      {regularError !== undefined ||
      regularData?.error?.message !== undefined ||
      adminError !== undefined ||
      adminData?.error?.message !== undefined ? (
        <Text style={{ color: "red" }}>
          {regularData?.error?.message ??
            regularError?.message ??
            adminData?.error?.message ??
            adminError?.message ??
            "Unknown error"}
        </Text>
      ) : (
        ""
      )}
      <Button onClick={() => sendUpdateConsumer()}>Save</Button>
      {regularLoading || adminLoading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        ""
      )}
    </Modal>
  );
};

import {
  ExpandableBlock,
  Button,
  Text,
  Divider,
  Modal,
} from "@itwin/itwinui-react";
import { useMemo, useState } from "react";
import {
  IAdminProducers,
  IEventDefinitionCosmosEntity,
} from "../../../interfaces/IAdminProducers";
import { IConsumerAdminEntity } from "../../../interfaces/IPagedAdminConsumers";
import { EventDefAuditTrailModal } from "../../eventDefAuditTrailModal/eventDefAuditTrailModal";
import { GovernanceButton } from "../governanceButton/governanceButton";
import { SubscribedConsumersExpandableBlock } from "../subscribedConsumersExpandableBlock/subscribedConsumersExpandableBlock";
import { EventDefinitionEditModal } from "./EventDefinitionEditModal/EventDefinitionEditModal";

export const EventDefinitionExpandableBlock = (props: {
  eventDef: IEventDefinitionCosmosEntity;
  consumers: IConsumerAdminEntity[];
  updateProducers: () => Promise<IAdminProducers>;
}) => {
  const { eventDef, consumers, updateProducers } = props;
  const [jsonModalIsOpen, setJsonModalIsOpen] = useState(false);
  const [eventDefEditModalIsOpen, setEventDefEditModalIsOpen] = useState(false);
  const [auditTrailModalIsOpen, setAuditTrailModalIsOpen] = useState(false);

  const title = useMemo(
    () => (
      <>
        {eventDef.eventType}{" "}
        {eventDef.state.toString() === "Pending" ? (
          <b style={{ color: "red" }}>(PENDING)</b>
        ) : (
          ""
        )}
      </>
    ),
    [eventDef.eventType, eventDef.state]
  );

  const subscribedConsumersToThisEvent = useMemo(() => {
    return consumers.filter((consumer) =>
      consumer.subscriptions.some(
        (sub) =>
          sub.producerId === eventDef.producerId &&
          (sub.events.length === 0 || sub.events.includes(eventDef.eventType))
      )
    );
  }, [consumers, eventDef.eventType, eventDef.producerId]);

  return (
    <ExpandableBlock
      title={title}
      style={{ marginBottom: "4px", marginTop: "4px" }}
    >
      <Text variant="body" as="p" style={{ marginBottom: "4px" }}>
        <b>Description: </b>
        {eventDef.description}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "4px" }}>
        <b>ResourceId Description: </b>
        {eventDef.resourceIdDescription}
      </Text>
      <Text variant="body" as="p" style={{ marginBottom: "4px" }}>
        <b>Created: </b>
        {new Date(eventDef.createdTimestamp).toDateString()}
      </Text>
      <Text variant="body" as="div" style={{ marginBottom: "12px" }}>
        <b>State: </b>
        {eventDef.state}{" "}
        {eventDef.state.toString() === "Pending" ? (
          <GovernanceButton
            governanceType="EventDefinition"
            id={eventDef.id}
            updateProducers={updateProducers}
          />
        ) : (
          ""
        )}
      </Text>
      <Button
        onClick={() => setJsonModalIsOpen(true)}
        style={{ marginBottom: "5px" }}
      >
        View JSON Event Definition
      </Button>
      <br />
      <Button
        onClick={() => setEventDefEditModalIsOpen(!eventDefEditModalIsOpen)}
      >
        Edit
      </Button>
      <Button
        onClick={() => setAuditTrailModalIsOpen(!auditTrailModalIsOpen)}
        style={{ marginLeft: "10px" }}
      >
        Audit Trail
      </Button>
      <EventDefinitionEditModal
        eventDefinition={eventDef}
        setModalIsOpen={setEventDefEditModalIsOpen}
        modalIsOpen={eventDefEditModalIsOpen}
        updateProducers={updateProducers}
      />
      <EventDefAuditTrailModal
        id={eventDef.id}
        setModalIsOpen={setAuditTrailModalIsOpen}
        modalIsOpen={auditTrailModalIsOpen}
      />
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <Text variant="body" as="div" style={{ marginBottom: "8px" }}>
        <SubscribedConsumersExpandableBlock
          consumers={subscribedConsumersToThisEvent}
        />
      </Text>
      <Modal
        isOpen={jsonModalIsOpen}
        onClose={() => setJsonModalIsOpen(false)}
        closeOnEsc
        isDismissible
        title={`${eventDef.eventType} - Raw Json`}
      >
        <div>
          <pre>{JSON.stringify(eventDef, null, 4)}</pre>
        </div>
      </Modal>
    </ExpandableBlock>
  );
};

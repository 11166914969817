import { useCallback, useEffect, useMemo, useState } from "react";
import { FullPageLoader } from "../../../components/fullPageLoader/fullPageLoader";
import {
  Button,
  ExpandableBlock,
  Flex,
  IconButton,
  Input,
} from "@itwin/itwinui-react";
import { useGetAdminProducersData } from "../../../hooks/useGetAdminProducersData/useGetAdminProducersData";
import { FullPageError } from "../../../components/fullPageError/fullPageError";
import { Svg403 } from "@itwin/itwinui-illustrations-react";
import { useAdminAuthenticationContext } from "../../../context/adminAuthenticationContext/adminAuthenticationContext";
import { useGetAdminConsumersData } from "../../../hooks/useGetAdminConsumersData/useGetAdminConsumersData";
import { AdminProducerTile } from "../../../components/adminProducerTile/adminProducerTile";
import { IConsumerAdminEntity } from "../../../interfaces/IPagedAdminConsumers";
import { SvgCollapse, SvgExpand, SvgRefresh } from "@itwin/itwinui-icons-react";
import { ConsumerTile } from "../../../components/consumerTile/consumerTile";
import { IProducer } from "../../../interfaces/IProducer";
import { SBQueueMonitorModal } from "../../../components/sbQueueMonitorModal/sbQueueMonitorModal";
import { SBDrainerModal } from "../../../components/sbDrainerModal/sbDrainerModal";

export const AdminPortalPage = () => {
  const [producers, producersLoading, , getProducers] =
    useGetAdminProducersData();
  const [consumers, consumersLoading, , getConsumers] =
    useGetAdminConsumersData();
  const { isAdmin } = useAdminAuthenticationContext();
  const [consumerSearchText, setConsumerSearchText] = useState("");
  const [expandAllConsumers, setExpandAllConsumers] = useState(false);
  const [queuesMonitorModalIsOpen, setQueuesMonitorModalIsOpen] =
    useState(false);
  const [sbDrainerModalIsOpen, setSBDrainerModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchProducersAndConsumersAsync = async () => {
      await getProducers();
      await getConsumers();
    };

    fetchProducersAndConsumersAsync();
  }, [getConsumers, getProducers]);

  const refreshConsumers = useCallback(() => {
    getConsumers(false);
  }, [getConsumers]);

  const groupedConsumers = useMemo(() => {
    if (!consumers || consumersLoading) {
      return new Map<string, IConsumerAdminEntity[]>();
    }

    return consumers.reduce<Map<string, IConsumerAdminEntity[]>>(
      (groups, consumer) => {
        // Definitely not the most efficient search, refactor later when we get more consumers 😉
        let rawJson = JSON.stringify(consumer).toLocaleLowerCase();
        if (
          consumerSearchText !== "" &&
          !rawJson.includes(consumerSearchText.toLocaleLowerCase())
        ) {
          return groups;
        }

        let creatorId = consumer.creatorId;
        let existingGroup = groups.get(creatorId);
        if (existingGroup) {
          existingGroup.push(consumer);
        } else {
          groups.set(creatorId, [consumer]);
        }
        return groups;
      },
      new Map<string, IConsumerAdminEntity[]>()
    );
  }, [consumers, consumersLoading, consumerSearchText]);

  if (!isAdmin) {
    return (
      <FullPageError
        svg={<Svg403 />}
        heading="You do not have access to this page."
      />
    );
  }

  return producers && !producersLoading && consumers && !consumersLoading ? (
    <>
      <div style={{ margin: "0 5%" }}>
        <h1>Producers:</h1>
        <div>
          <Flex
            alignItems="flex-start"
            style={{ width: "100%" }}
            flexWrap="wrap"
          >
            {producers.producers.map((producer) => (
              <Flex.Item key={producer.id} flex="1 1 20%">
                <AdminProducerTile
                  producer={producer}
                  consumers={consumers}
                  updateProducers={getProducers}
                />
              </Flex.Item>
            ))}
          </Flex>
        </div>
        <h1>Consumers:</h1>
        <Input
          style={{ width: "500px", marginBottom: "1em" }}
          placeholder="Search"
          onChange={(event) => setConsumerSearchText(event.target.value)}
        />
        <Button
          style={{ marginLeft: "1em", top: "-2px" }}
          endIcon={expandAllConsumers ? <SvgCollapse /> : <SvgExpand />}
          onClick={() => setExpandAllConsumers(!expandAllConsumers)}
        >
          {expandAllConsumers ? "Collapse All" : "Expand All"}
        </Button>
        <IconButton
          onClick={refreshConsumers}
          style={{ marginLeft: "1em", top: "-2px" }}
        >
          <SvgRefresh />
        </IconButton>
        <Button
          onClick={() => {
            setQueuesMonitorModalIsOpen(true);
          }}
          style={{ marginLeft: "1em", top: "-2px" }}
        >
          Queues Monitor
        </Button>
        <Button
          onClick={() => {
            setSBDrainerModalIsOpen(true);
          }}
          style={{ marginLeft: "1em", top: "-2px" }}
        >
          SB Message Drainer
        </Button>
        <SBQueueMonitorModal
          modalIsOpen={queuesMonitorModalIsOpen}
          setModalIsOpen={setQueuesMonitorModalIsOpen}
        />
        <SBDrainerModal
          modalIsOpen={sbDrainerModalIsOpen}
          setModalIsOpen={setSBDrainerModalIsOpen}
        />

        {/* Will probably eventually need something like react-virtualized here to help display lots of consumers */}
        {Array.from(groupedConsumers.keys()).map((creatorId) => (
          <div key={creatorId}>
            <ExpandableBlock
              title={`${creatorId} (GPRId: ${
                groupedConsumers.get(creatorId)![0].serviceGPRId
              }) (${groupedConsumers.get(creatorId)!.length}) `}
              style={{ marginBottom: "1em" }}
              isExpanded={expandAllConsumers}
            >
              <Flex
                alignItems="flex-start"
                style={{ width: "100%" }}
                flexWrap="wrap"
              >
                {groupedConsumers.get(creatorId)!.map((consumer) => (
                  <Flex.Item key={consumer.id} flex="1 1 20%">
                    <ConsumerTile
                      consumer={consumer}
                      subscriptions={consumer.subscriptions}
                      producers={producers.producers as IProducer[]}
                      refreshConsumers={refreshConsumers}
                      refreshSubscriptions={() => {}}
                      key={consumer.id}
                      isAdmin={true}
                    />
                  </Flex.Item>
                ))}
              </Flex>
            </ExpandableBlock>
          </div>
        ))}
      </div>
    </>
  ) : (
    <FullPageLoader loadingText="Loading admin portal..." />
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";

export const AuthenticateUserHandler = () => {
  const { userManager, setUser, user } = useAuthenticationContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user === null || user.expired) {
      navigate(`/login${window.location.pathname}`)
    }

    if (userManager && userManager.events) {
      userManager.events.addAccessTokenExpired(async () => {
        await userManager.signinRedirect({
          state: { from: `${window.location.origin}${window.location.pathname}` },
        });
      });

      userManager.events.addUserLoaded(async () => {
        try {
          const user = await userManager.getUser();

          if (user != null && !user.expired) {
            setUser(user);
          } else {
            throw new Error("User was null");
          }
        } catch (e) {
          console.log("Failed to getUser:", e);
          await userManager.signinRedirect({
            state: { from: `${window.location.origin}` },
          });
        }
      });

      userManager.events.addSilentRenewError((e) => {
        console.log("silent renew error", e.message);
      });
    }
  }, [navigate, setUser, user, userManager]);

  return <></>
};

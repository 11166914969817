import { SvgDelete, SvgEdit } from "@itwin/itwinui-icons-react";
import {
  Button,
  Modal,
  ExpandableBlock,
  ProgressRadial,
  Text,
} from "@itwin/itwinui-react";
import { useCallback, useMemo, useState } from "react";
import { useDeleteConsumer } from "../../hooks/useDeleteConsumer/useDeleteConsumer";
import { useDeleteSubscription } from "../../hooks/useDeleteSubscription/useDeleteSubscription";
import { IProducerAdminEntity } from "../../interfaces/IAdminProducers";
import { IConsumer } from "../../interfaces/IConsumers";
import {
  IConsumerCosmosEntity,
  ISubscriptionCosmosEntity,
} from "../../interfaces/IPagedAdminConsumers";
import { IProducer } from "../../interfaces/IProducer";

export const ConsumerDeletionModal = (props: {
  consumer: IConsumerCosmosEntity;
  modalIsOpen: boolean;
  setModalIsOpen: (open: boolean) => void;
  refreshConsumers: () => void;
}) => {
  const { consumer, modalIsOpen, setModalIsOpen, refreshConsumers } = props;
  const [succeeded, loading, error, deleteConsumer] = useDeleteConsumer();

  const deleteConsumerCall = async () => {
    await deleteConsumer(consumer.id);
    if (!error) {
      setModalIsOpen(false);
      refreshConsumers();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      closeOnEsc
      isDismissible
      title={`Confirm Deletion`}
    >
      <div>Are you sure you want to delete this consumer {consumer.id}?</div>
      <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
        {" "}
        - {consumer.description}
      </div>
      <Button onClick={deleteConsumerCall}>Confirm</Button>
      <Button
        onClick={() => setModalIsOpen(false)}
        style={{ marginLeft: "5px" }}
      >
        Cancel
      </Button>
      {error !== undefined ? (
        <Text style={{ color: "red" }}>{"Unknown error"}</Text>
      ) : (
        ""
      )}
      {loading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        ""
      )}
    </Modal>
  );
};

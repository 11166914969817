import { NodeData, Tree, TreeNode } from '@itwin/itwinui-react';
import { useCallback, useMemo } from 'react';
import "./markdownSidebarNavigation.css";
import { useNavigate } from 'react-router';
import { generateSlug } from '../../utils/generateSlug';

type TreeData = {
  id: string;
  label: string;
  subItems: TreeData[];
};

export const MarkdownSidebarNavigation = (props: {
  headings: string[],
  scrollToComponent: () => void
}) => {
  const { headings, scrollToComponent } = props;
  const navigate = useNavigate();

  const headingsData = useMemo(() => {
    const treeHeadings = [] as TreeData[]
    var previousDepth = 999;
    for (const heading of headings) {
      var depth = heading.split(" ")[0].length;
      var label = heading.replaceAll("#", "")

      if (depth >= previousDepth) {
        treeHeadings[treeHeadings.length - 1].subItems.push({
          id: label + treeHeadings.length,
          label,
          subItems: [] as TreeData[]
        })
      } else {
        treeHeadings.push({
          id: label,
          label,
          subItems: [] as TreeData[]
        })
      }

      previousDepth = depth;
    }

    return treeHeadings;
  }, [headings]);

  const getNode = useCallback(
    (node: TreeData): NodeData<TreeData> => {
      return {
        subNodes: node.subItems,
        nodeId: node.id,
        node: node,
        isExpanded: true,
        hasSubNodes: node.subItems.length > 0,
      };
    }, []
  );

  return (
    <Tree<TreeData>
      style={{
        verticalAlign: "unset",
        borderRadius: "unset",
        backgroundColor: "unset",
        padding: "16px 0 0 0",
        height: "100%"
      }}
      data={headingsData}
      getNode={getNode}
      nodeRenderer={
        ({ node, ...rest }) => (
          <div onClick={() => {
            navigate("#" + generateSlug(node.label))
            scrollToComponent();
          }}>
            <TreeNode
              label={node.label}
              onExpanded={() => { }}
              {...rest}
            />
          </div>
        )}
    />
  );
};

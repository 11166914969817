import { useCallback, useState } from "react";
import { ISubscriptionCreateRequest } from "../../interfaces/ISubscriptionCreateRequest";
import { useConsumerController } from "../useConsumerController/useConsumerController";

export const usePatchSubscription = (): [
  any | undefined,
  boolean,
  Error | undefined,
  (
    consumerId: string,
    subscriptionId: string,
    subscriptionPatchRequest: Omit<ISubscriptionCreateRequest, "producerId">
  ) => Promise<any>
] => {
  const [data, setData] = useState<any>();

  const { patch, response, loading, error } = useConsumerController<any>(false);

  const patchConsumer = useCallback(
    async (
      consumerId: string,
      subscriptionId: string,
      subscriptionPatchRequest: Omit<ISubscriptionCreateRequest, "producerId">
    ) => {
      const consumer = await patch(
        `/${consumerId}/subscriptions/${subscriptionId}`,
        subscriptionPatchRequest
      );

      setData(consumer);

      return consumer;
    },
    [patch]
  );

  return [data, loading, error, patchConsumer];
};

import React, { useLayoutEffect } from "react";
import "./layout.css";
import { useAuthenticationContext } from "../../context/authenticationContext/authenticationContext";
import { FullPageLoader } from "../fullPageLoader/fullPageLoader";
import { AuthenticateUserHandler } from "../authenticateUserHandler/authenticateUserHandler";
import EventsServiceHeader from "../eventsServiceHeader/eventsServiceHeader";
import { Footer } from "@itwin/itwinui-react";
import { PageLayout } from "@itwin/itwinui-layouts-react";
import { useLocation } from "react-router";

const Layout: React.FunctionComponent<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const { user } = useAuthenticationContext();

  const location = useLocation();
  useLayoutEffect(() => {
    const contentContainer = document.querySelector('.iui-layouts-page-content')
    if (contentContainer) {
      contentContainer.scrollTo(0, 0)
    }
  }, [location.pathname]);

  return (
    <>
      {user !== null ? (
        <>
          <PageLayout.Header>
            <EventsServiceHeader user={user} />
          </PageLayout.Header>

          <PageLayout.Content>
            {children}
            <Footer />
          </PageLayout.Content>
        </>
      ) : (
        <FullPageLoader loadingText="Loggin' in" />
      )}

      <AuthenticateUserHandler />
    </>
  );
};

export default Layout;

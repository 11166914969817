export const generateServiceBusQueueURL = (queueName: string) => {
  let url = window.location.href;
  let subscriptionId = url.includes("dev-")
    ? "13cac5cb-2ec6-48c6-9a90-5a776f6aed90"
    : url.includes("qa-")
    ? "7a1732fd-79cf-41e1-bf8e-f994853fa611"
    : "57b27da1-0c0d-4c97-9deb-5f0bf6df29e6";
  let env = url.includes("dev-") ? "dev" : url.includes("qa-") ? "qa" : "prod";

  return `https://portal.azure.com/#@bentley.onmicrosoft.com/resource/subscriptions/${subscriptionId}/resourceGroups/${env}-itwinevents-eus-rg/providers/Microsoft.ServiceBus/namespaces/itwinevents-${env}-eus-sb-01/queues/${queueName}/overview`;
};

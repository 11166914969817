import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Label,
  LabeledInput,
  LabeledSelect,
  ProgressRadial,
  Flex,
  Text,
  Checkbox,
} from "@itwin/itwinui-react";
import { useCreateSubscription } from "../../hooks/useCreateSubscription/useCreateSubscription";
import { ISubscriptionCreateRequest } from "../../interfaces/ISubscriptionCreateRequest";
import { ISubscription } from "../../interfaces/ISubscriptions";
import { IDiscovery } from "../../interfaces/IDiscovery";

export const SubscriptionCreation = (props: {
  consumerId: string;
  discovery: IDiscovery;
  setNewSubscription: (subscription: ISubscription) => void;
}) => {
  const { consumerId, discovery, setNewSubscription } = props;
  const [subscriptionCreation, setSubscriptionCreation] =
    useState<ISubscriptionCreateRequest>({
      producerId: "",
      status: "Active",
      accountIdFilter: undefined,
      iTwinIdFilter: undefined,
      resourceDataCenterIdFilter: undefined,
      iTwinDataCenterIdFilter: undefined,
      events: [],
    });
  const [subscription, loading, error, createSubscription] =
    useCreateSubscription();

  const producerNameOptions = useMemo(() => {
    return discovery.producers.reduce((acc, cur) => {
      acc.push({ label: cur.name, value: cur.id });
      return acc;
    }, [] as Array<{ label: string; value: string }>);
  }, [discovery.producers]);

  const toggleEvent = useCallback(
    (eventType: string, isChecked: boolean) => {
      let events = subscriptionCreation.events;
      if (events.includes(eventType) && !isChecked) {
        events = events.filter((x) => x !== eventType);
        setSubscriptionCreation({ ...subscriptionCreation, events });
      }
      if (!events.includes(eventType) && isChecked) {
        events.push(eventType);
        setSubscriptionCreation({ ...subscriptionCreation, events });
      }
    },
    [subscriptionCreation]
  );

  useEffect(() => {
    setSubscriptionCreation({
      ...subscriptionCreation,
      events:
        discovery?.producers
          ?.filter((x) => x.id === subscriptionCreation.producerId)
          .at(0)
          ?.eventDefinitions?.map((eventDef) => eventDef.eventType) ?? [],
    });
  }, [subscriptionCreation.producerId]);

  useEffect(() => {
    if (
      error === undefined &&
      subscription !== undefined &&
      subscription?.subscription !== undefined
    ) {
      console.log("Setting new sub");
      setNewSubscription(subscription as ISubscription);
    }
  }, [error, subscription]);

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Disabled", label: "Disabled" },
  ];

  return (
    <>
      <LabeledSelect
        label="Producer"
        options={producerNameOptions ?? []}
        value={subscriptionCreation.producerId}
        onChange={(value) =>
          setSubscriptionCreation({
            ...subscriptionCreation,
            producerId: value,
          })
        }
        style={{ marginBottom: "12px", width: "50%" }}
      />
      <Label>Events</Label>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        style={{ width: "100%", flexWrap: "wrap" }}
      >
        {subscriptionCreation?.producerId !== "" &&
          discovery?.producers
            .find((x) => x.id === subscriptionCreation.producerId)
            ?.eventDefinitions?.map((eventDef) => {
              return (
                <Flex.Item flex="1" key={eventDef.eventType}>
                  <Checkbox
                    label={eventDef.eventType}
                    onChange={(event) =>
                      toggleEvent(eventDef.eventType, event.target.checked)
                    }
                    checked={subscriptionCreation?.events.includes(
                      eventDef.eventType
                    )}
                  />
                </Flex.Item>
              );
            })}
        {subscriptionCreation?.producerId === "" && (
          <b>Select a producer to view available events</b>
        )}
      </Flex>

      <LabeledSelect
        label="Status"
        options={statusOptions}
        value={subscriptionCreation.status}
        onChange={(value) =>
          setSubscriptionCreation({
            ...subscriptionCreation,
            status: value,
          })
        }
        style={{ marginBottom: "12px", width: "50%" }}
      />
      <Flex flexDirection="row" style={{ width: "100%" }} flexWrap="wrap">
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="Account Id Filter"
            value={subscriptionCreation.accountIdFilter}
            onChange={(event) =>
              setSubscriptionCreation({
                ...subscriptionCreation,
                accountIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="iTwin Id Filter"
            value={subscriptionCreation.iTwinIdFilter}
            onChange={(event) =>
              setSubscriptionCreation({
                ...subscriptionCreation,
                iTwinIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="Resource Data Center Id"
            value={subscriptionCreation.resourceDataCenterIdFilter}
            onChange={(event) =>
              setSubscriptionCreation({
                ...subscriptionCreation,
                resourceDataCenterIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
        <Flex.Item flex="1 1 33%">
          <LabeledInput
            label="iTwin Data Center Id"
            value={subscriptionCreation.iTwinDataCenterIdFilter}
            onChange={(event) =>
              setSubscriptionCreation({
                ...subscriptionCreation,
                iTwinDataCenterIdFilter: event.target.value,
              })
            }
            style={{ marginBottom: "12px" }}
          />
        </Flex.Item>
      </Flex>
      {error !== undefined || subscription?.error?.message !== undefined ? (
        <Text style={{ color: "red" }}>
          {subscription?.error?.message ?? error?.message ?? "Unknown error"}
        </Text>
      ) : (
        ""
      )}
      {loading ? (
        <ProgressRadial indeterminate style={{ marginLeft: "20px" }} />
      ) : (
        ""
      )}

      <Flex flexDirection="row">
        <Button
          onClick={() => createSubscription(consumerId, subscriptionCreation)}
          disabled={subscriptionCreation.events.length == 0}
        >
          Create
        </Button>

        {subscriptionCreation.producerId &&
          subscriptionCreation.events.length == 0 && (
            <Text style={{ color: "red" }}>
              Subscriptions must be created with events.
            </Text>
          )}
      </Flex>
    </>
  );
};
